.container {
  display: flex;
  justify-content: space-between;

  padding: 10px 15px;
  border: 1px solid #ffe27f;
  border-radius: 16px;
  background-color: rgba(255, 226, 127, 0.3);

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.copy {
  padding-left: 20px;

  color: #17a2c6;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
}

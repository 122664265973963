.title {
  word-break: break-word;
}

.body {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.buttons {
  display: flex;
  gap: 10px;
}

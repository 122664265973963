.wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 1px;
  padding: 0;
}

.scrollContent {
  overflow-y: auto;
  flex: 1;
  overscroll-behavior: contain;
}

.shadow {
  position: absolute;
  left: 0;
  right: 0;
  height: 9px;
  pointer-events: none;
  z-index: 5;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.visible {
    opacity: 1;
  }

  &.top {
    top: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
  }

  &.bottom {
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
  }
}

.voucher {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  background-color: rgba(23, 162, 198, 1);
  border-radius: 10px;

  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  color: #ffffff;

  span {
    margin-right: 5px;
  }
}

.closeIcon {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.selector {
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;

  span {
    margin-left: 5px;
  }
}

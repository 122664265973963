@import '@assets/styles/mixins';

.mobile {
  position: relative;
  margin-bottom: 30px;
  padding: 0 15px;

  &Address {
    text-align: center;
    margin: -36px 45px 14px;
    position: relative;
    z-index: 5;

    &Title {
      font-size: 18px;
      line-height: 1.33;
      font-weight: bold;
      @include ellipsis;
    }

    &Meta {
      @include text-meta;
      @include ellipsis;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      z-index: 3;
    }

    &Arrow {
      width: 20px;
      height: 20px;
      position: relative;
      bottom: 1px;
    }

    &Image {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &Map {
    position: relative;
    height: 268px;
    transition: all linear 0.2s;

    &Inner {
      position: relative;
      width: 100%;
      height: 100%;
      background: #f6f6f6;
      border-radius: 16px;
      overflow: hidden;
    }

    &ButtonsWrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      position: absolute;
      bottom: 15px;
      width: calc(100% - 30px);
      left: 50%;
      transform: translate(-50%);
      gap: 10px;
      @media (max-width: 500px) {
        flex-direction: column;
      }
    }

    &Button {
      min-width: 192px;
      width: 50%;
      @media (max-width: 500px) {
        width: 100%;
      }
    }

    &Btn {
      font-weight: 600;
      border-radius: 100px;
      padding: 18px;
      white-space: nowrap;
    }

    &Warning {
      top: -30px;

      text-align: center;

      font-weight: 600;
      border-radius: 100px;
      padding: 18px;
      white-space: nowrap;

      border: solid 2px #ffe27f;
      background-color: #fff6d8;
    }
  }

  .collapsed {
    height: 0;
    transition: all linear 0.2s;

    .mobileMapButtonsWrapper {
      display: none;
    }
  }

  &MapHeight {
    @media (max-width: 500px) {
      height: 350px;
    }
  }
}

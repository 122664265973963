@mixin ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin line-clamp($n) {
  display: -webkit-box;
  -webkit-line-clamp: $n;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin sticky($top: 0) {
  position: sticky;
  top: $top;
}

@mixin text-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: #000000;
}

@mixin text-meta {
  font-size: 13px;
  font-weight: normal;
  line-height: 1.54;
  color: #8e8e93;
}

@mixin a {
  color: #17a2c6;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  &:hover, &:focus {
    color: #1386a4;
    text-decoration: none;
  }
}

@mixin title {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  padding-bottom: 0;
  margin-bottom: 0;
}

@mixin title-xs {
  @include title;

  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
}

@mixin title-sm {
  @include title;

  font-size: 18px;
  line-height: 1.56;
}

@mixin title-md {
  @include title;

  font-size: 20px;
  line-height: 1.36;

  @media (max-width: 991.98px) {
    line-height: 1.27;
  }
}

@mixin title-lg {
  @include title;

  font-size: 32px;
  line-height: 1.5;

  @media (max-width: 991.98px) {
    font-size: 22px;
    line-height: 1.27;
  }
}

@mixin hide-scrollbar {
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
}

.maxWidth860 {
  max-width: 860px;
}

.body {
  margin: 0 auto;

  :global(.container) .maxWidth860 {
    margin: 0 auto;
  }

  :global(.search-item) {
    background-color: #f6f6f6;
  }

  &:global(.box--desktop-footer-fixed .box-footer .btn.mt-10) {
    margin-top: 10px;
  }

  &:global(.box--desktop-footer-fixed .box-footer .btn.mt-20) {
    margin-top: 20px;
  }
}

:global(.sidebar) + :global(.content-wrapper) .body {
  @media (min-width: 992px) {
    margin: 0;

    &:global(.box--desktop-footer-fixed .box-footer) {
      left: 260px;
    }

    :global(.container) .maxWidth860 {
      margin: 0;
    }
  }
}

:global(.box--desktop-footer-fixed) :global(.box-footer).footer {
  padding: 30px 0;

  @media (max-width: 991.98px) {
    padding: 15px 0;
  }
}

.footer {
  :global(.btn.btn-primary[disabled]:not(.progress_button--active)),
  :global(.btn.btn-primary.disabled:not(.progress_button--active)) {
    background-color: #f6f6f6;
  }

  :global(.btn) {
    margin-top: auto;
  }
}

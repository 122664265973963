.resenderBtn {
  line-height: 1.25;
  color: #8d8e93;
  cursor: pointer;
  user-select: none;

  &Disabled {
    color: #d1d1d6;
    cursor: default;
  }
}

.bubbleLoader {
  margin-right: 9px;
}

.container {
  position: relative;

  width: 100%;
  height: 260px;
  margin-bottom: 30px;
  border-radius: 16px;

  &Collapsed {
    height: 84px;

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 100%;
      padding: 0 20px 0 78px;
    }

    .addressLabel {
      color: #8e8e93;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }

    .addressValue {
      font-size: 18px;
      line-height: 24px;
    }

    .map {
      :global(.g-map) {
        width: 200%;
        height: 200%;
        left: calc(-100% + 48px);
        top: -17.5%;
      }
    }

    .buttons {
      margin-top: 0;
    }
  }
}

.map {
  position: absolute;

  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
}

.content {
  position: relative;
  padding: 40px 0 0 30px;
}

.address {
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
}

.buttons {
  display: flex;
  gap: 10px;

  margin-top: 20px;
}

.icon {
  position: relative;

  &:before {
    position: absolute;
    left: -5px;
    top: -5px;
    right: -5px;
    bottom: -5px;
    display: block;
    content: '';
    border-radius: 12px;
    border: solid 1px rgba(0, 0, 0, 0.08);
    background-color: #e3e3e4;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &.isHoverable:hover:before,
  &.isActive:before {
    opacity: 0.88;
  }

  .inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 8px;

    background-color: #ffffff;

    img {
      max-width: 100%;
    }

    &.firstLetters {
      background-color: #17a2c6;
      color: #ffffff;
      font-weight: 600;
      font-size: 18px;
      line-height: 1;
      text-transform: uppercase;
    }

    &.empty {
      background-color: #17a2c6;

      img {
        max-width: 24px;
        max-height: 24px;
      }
    }
  }

  &.secondary .inner {
    &.firstLetters {
      background-color: #ffffff;
      color: rgba(0, 0, 0, 0.2);
    }

    &.empty {
      background-color: #ffffff;
    }
  }
}

.apptDetails {
  .content {
    display: none;
    margin-bottom: 20px;
  }

  .content.expanded {
    display: block;
  }

  .button {
    margin: 0;
    font-weight: 600;
  }
}

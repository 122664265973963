.toolbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 12;
  box-sizing: border-box;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 15px 2px;
  min-height: 44px;

  pointer-events: none;

  & > * {
    pointer-events: auto;
  }

  transition: background-color 0.3s ease-in-out,
  box-shadow 0.3s ease-in-out,
  left 0.4s ease-in-out;

  @media (max-width: 991.98px) {
    pointer-events: auto;
    padding: 8px 15px;
  }

  &Fixed {
    .goBack,
    .rightSidebarButton {
      box-shadow: 0 4px 12px 2px rgba(0, 0, 0, 0.15);
    }

    @media (max-width: 991.98px) {
      background-color: #ffffff;
      box-shadow: 0 8px 24px #959da533;

      .goBack,
      .rightSidebarButton {
        box-shadow: none;
      }
    }
  }
}

.goBack,
.rightSidebarButton {
  transition: box-shadow 0.3s ease-in-out;
}

.rightSidebarButton {
  &Hidden {
    visibility: hidden;
  }

  &DisplayNone {
    display: none;
  }
}

.rightSidebar {
  display: flex;
  gap: 10px;

  &AlignRight {
    margin-left: auto;
  }
}

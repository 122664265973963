.group {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.itemsContainer {
  background-color: #f6f6f6;
  border-radius: 10px;
  overflow: hidden;
}

.item {
  position: relative;
  display: block;
  padding: 15px 50px 15px 20px;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 1.25;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #eeefef;
  }

  & + & {
    border-top: 1px solid rgba(206, 206, 208, 0.5);
  }
}

.icon {
  background-color: transparent;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);

  &,
  &:before {
    display: block;
    position: absolute;
    width: 28px;
    height: 28px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:before {
    left: 0;
    top: 0;
    content: '';
    transition: opacity 0.3s ease-in-out;
    opacity: 0.2;
    background-image: url('~ziphy-web-shared/basic/assets/images/ui/next.svg');
  }

  .item:hover &:before {
    opacity: 1;
  }
}

.btn {
  position: relative;
  padding: 8px;
  cursor: pointer;
  width: 44px;
  height: 44px;
  cursor: pointer;

  @media (min-width: 992px) {
    opacity: 0.25;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
}

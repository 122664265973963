// https://fkhadra.github.io/react-toastify/how-to-style

:global(:root #root) {
  --toastify-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
  --toastify-toast-width: 470px;
  --toastify-toast-min-height: 44px;

  --toastify-color-error: rgba(0, 0, 0, 0.7);
  --toastify-text-color-error: #ffffff;

  --toastify-color-success: rgba(0, 0, 0, 0.7);
  --toastify-text-color-success: #ffffff;

  :global(.Toastify__toast-container--top-center) {
    top: 10px;

    @media (max-width: 480px) {
      width: calc(100vw - 20px);
      margin-left: 10px;
    }
  }

  :global(.Toastify__toast) {
    border-radius: 16px;
    padding: 6px 9px;
    box-shadow: 0 2px 8px 0 rgba(0, 17, 77, 0.17);
  }
}

.title {
  font-weight: 600;
  line-height: 1.25;
  color: #ffffff;
}

.content {
  a {
    font-weight: 600;
    color: #75c3d7;
  }
}

.toolbarGoBack {
  position: relative;
  display: inline-block;
  cursor: pointer;
  border-radius: 10px;

  width: 28px;
  height: 28px;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-in-out;
    color: #000000;
  }

  @media (min-width: 992px) {
    width: 36px;
    height: 36px;
    margin-left: -5px;
    background-color: #f6f6f6;
    transition: background-color 0.3s ease-in-out;

    .icon {
      opacity: 0.25;
    }

    &:hover {
      background-color: #f0f0f0;

      .icon {
        opacity: 1;
      }
    }
  }
}

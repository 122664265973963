.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 13;
  pointer-events: none;

  background-color: rgba(0, 0, 0, 0);
  transition: background-color 300ms ease-in-out;

  .container {
    max-width: 680px;
    width: 100%;

    transform: translateX(100%);
    transition: transform 300ms ease-in-out;
  }

  &Visible {
    pointer-events: auto;
    background-color: rgba(0, 0, 0, 0.3);

    .container {
      transform: translateX(0%);
    }
  }

  @media (max-width: 991.98px) {
    .container {
      max-width: 100%;
    }
  }
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  transition: bottom 0.3s ease-in-out;
  transform: translateX(100%);

  width: 100%;
  border-left: 1px solid rgba(206, 206, 208, 0.5);
  pointer-events: auto;

  &Visible {
    transform: translateX(0%);
  }
}

.wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  .inner {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      margin-bottom: 2px;

      .label {
        font-size: 13px;
        font-weight: 600;
        line-height: 1.54;
        margin: 0;
      }

      .actionsContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;

        .action {
          font-size: 13px;
          line-height: 1.54;
          text-align: right;
          color: #18a2c6;
          cursor: pointer;
          transition: color 0.3s ease-in-out;

          &:hover,
          &:focus {
            color: #1386a4;
          }
        }
      }
    }

    .body {
      width: 100%;
      display: flex;
      align-items: center;

      border: 1px solid #e6e6e8;
      border-radius: 10px;

      .fieldContainer {
        width: 100%;
        position: relative;
        padding: 8px 15px;

        textarea {
          width: 100%;
          border: none;
          border-radius: 0;
          outline: none;
          font-size: 16px;
          line-height: 1.5;
          font-weight: normal;
          color: #000000;
          background: transparent;

          &::placeholder {
            color: #d1d1d6;
          }

          &[type='number'] {
            -moz-appearance: textfield;
          }

          &[type='text']::-ms-clear {
            display: none;
            width: 0;
            height: 0;
          }

          &[type='text']::-ms-reveal {
            display: none;
            width: 0;
            height: 0;
          }

          &[type='search']::-webkit-search-decoration,
          &[type='search']::-webkit-search-cancel-button,
          &[type='search']::-webkit-search-results-button,
          &[type='search']::-webkit-search-results-decoration {
            display: none;
          }
        }
      }

      .iconClear {
        width: 24px;
        min-width: 24px;
        height: 24px;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);

        border-radius: 100%;
        background: #b5b6b6 url('~ziphy-web-shared/basic/assets/images/ui/close-white.svg') no-repeat center;
        background-size: cover;
        cursor: pointer;
        opacity: 0;
      }
    }
  }

  &:hover {
    .iconClear {
      opacity: 1 !important;
    }
  }

  .rmBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.25;
    width: 28px;
    min-width: 28px;
    height: 28px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    svg {
      width: 28px;
      height: 28px;
    }

    &:hover {
      opacity: 1;
    }
  }

  &.invalid {
    .body {
      border-color: #ff3b30;
    }
  }

  &.readonly {
    .inner {
      .body {
        background-color: #f6f6f6;
        cursor: default;

        textarea {
          cursor: default;
        }
      }
    }
  }

  &.lg {
    .inner {
      border: 1px solid #e6e6e8;
      border-radius: 16px;

      .header {
        margin-bottom: 0;
        padding: 9px 15px 0;

        .label {
          color: #8e8e93;
          font-weight: 400;
          transition: all 0.2s ease;
        }
      }

      .body {
        border: none;
        border-radius: 0;
        padding: 0;

        .fieldContainer {
          position: unset;

          textarea {
            padding-top: 0;
          }
        }
      }
    }

    &.invalid {
      .inner {
        border-color: #ff3b30;
      }

      .body {
        border-color: transparent;
      }
    }

    &.withRemove {
      .iconClear {
        right: 53px !important;
      }
    }

    &.readonly {
      .inner {
        background-color: #f6f6f6;
        cursor: default;
      }
    }
  }
}

.switch {
  display: flex;
  background-color: #eaeaea;
  border-radius: 100px;
  padding: 4px;
}

.item {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #b4b5b8;
  padding: 6px 10px;
  border-radius: 100px;
  cursor: pointer;

  &Active {
    color: #000;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 17, 77, 0.22);
  }
}

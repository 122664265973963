.dropdown {
  position: relative;
}

.popup {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;

  display: none;
  max-width: fit-content;

  min-height: 54px;

  padding: 5px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  &.visible {
    display: block;
  }

  .item {
    position: relative;
    display: block;
    padding: 10px 10px;

    outline: none;
    border: none;
    border-radius: 10px;

    color: #000000;

    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    white-space: wrap;

    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    &:hover {
      background-color: #f6f6f6;
    }

    &:focus {
      outline: none;
    }

    &.disabled {
      color: #d1d1d6;
      background-color: #f2f3f3;
      pointer-events: none;
      cursor: default;
    }
  }
}

.dropdown.hasSelected {
  .popup {
    .item {
      padding-right: 48px;

      &.selected {
        .icon {
          position: absolute;
          right: 10px;
          top: 6px;
          color: #17a2c6;
        }
      }
    }
  }
}

.title {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  padding-bottom: 0;
  margin-bottom: 0;
}

.h1 {
  font-size: 32px;
  line-height: 1.5;
  //
  //@media (max-width: 991.98px) {
  //  font-size: 22px;
  //  line-height: 1.27;
  //}
}

.h2 {
  font-size: 22px;
  line-height: 1.36;
  //
  //@media (max-width: 991.98px) {
  //  line-height: 1.27;
  //}
}

.h3 {
  font-size: 18px;
  line-height: 1.5;
}

.h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;
  width: 100%;
}

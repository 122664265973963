.containerDesktop,
.containerMobile {
  padding: 8px 15px;
  border-radius: 16px;
  background-color: #f6f6f6;
  min-height: 62px;

  & + & {
    margin-top: 10px;
  }
}

.containerDesktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .column:nth-child(1) {
    flex: 1;
  }

  .column:nth-child(2) {
    width: 43%;
  }

  .column:nth-child(3) {
    width: 7%;
  }
}

.containerMobile {
  &Header {
    display: flex;
    margin-bottom: 2px;
  }

  .column {
    margin-top: 10px;

    .insurance {
      width: 100%;
    }
  }
}

.column {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding-right: 15px;

  &:last-child {
    padding-right: 0;
  }

  &AlignEnd {
    margin-left: auto;
    align-items: flex-end;
  }
}

.row {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  &Label {
    color: #8e8e93;
    font-size: 13px;
    font-weight: 400;
  }
}

.noWrap {
  white-space: nowrap;
}

.loader {
  padding: 10px 15px 10px;
  opacity: 0.2;
}

.selectInsurance {
  margin-top: -2px;
}

.day-list-picker {
  padding-top: 10px;

  & > .header {
    display: flex;
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: 4px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
    border-bottom: solid 1px rgba(206, 206, 208, 0.5);

    .cell {
      flex: 1;
      text-align: center;
      font-size: 16px;
      line-height: 1.25;
      color: #8e8e93;
      user-select: none;
    }
  }

  & > .body {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 383px;
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;

    .day-picker-calendar {
      height: initial;

      .header .name-month {
        margin-top: 18px;
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-bottom: -10px;
}

.item {
  position: relative;
  margin-left: 10px;
  margin-bottom: 10px;
  width: 200px;
  height: 112px;
  border-radius: 16px;
  overflow: hidden;
}

.details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 13px;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 1;
  opacity: 0.2;
  transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 0 0 16px 16px;

  .item:hover & {
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 1;
  }

  .title {

  }

  .meta {

  }
}

.dropdown {
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: #fff;
  list-style: none;
  margin: 0;
  max-height: 360px;
  height: 100%;

  .inner {
    overflow: auto;
    overscroll-behavior-y: contain;
    padding: 0 15px;
    height: 100%;
  }

  .search {
    background: #fff;
    padding: 10px 0;
    z-index: 1;
    width: calc(100% - 30px);
    margin-left: 15px;

    &Item {
      display: flex;
      position: relative;
      padding: 6px 15px 6px 48px;
      border-radius: 10px;
      background-color: #f6f6f6;
      overflow: hidden;

      &:before {
        display: block;
        content: '';
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 28px;
        height: 28px;
        background: url('~ziphy-web-shared/basic/assets/images/ui/search.svg') no-repeat center;
        opacity: 0.25;
      }
    }

    &Field {
      flex: 1;
      display: block;
      width: 100%;
      padding: 4px 0;
      border: none;
      outline: none;

      font-size: 14px;
      line-height: 1.43;
      font-weight: 600;
      color: #000000;
      background: transparent;

      &::placeholder {
        color: #d1d1d6;
      }
    }

    &Clear {
      order: 1;
      margin: 4px 0 0 5px;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background: #b5b6b6 url('~ziphy-web-shared/basic/assets/images/ui/close-white.svg') no-repeat center;
      background-size: cover;
      cursor: pointer;
    }

    &NoResults {
      padding: 10px 0;
    }
  }

  & ul {
    margin: 0;
    padding: 0;
  }

  .item {
    background: #fff;
    border: 1px solid #e6e6e7;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: space-between;
    outline: none;
    padding: 0 10px 0 15px;
    position: relative;
    transition: border-color 0.2s ease;
    width: 100%;

    &Title {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      color: #8e8e93;
      padding-top: 10px;
    }

    &Sub {
      cursor: pointer;
      position: relative;
      grid-template-columns: 1fr auto;
      padding: 10px 33px 10px 0;

      display: flex;
      flex-wrap: wrap;

      .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
      }

      span {
        width: initial;
        flex-basis: 100%;
        white-space: pre-wrap;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.5;
        color: #8e8e93;
      }

      &:nth-of-type(n + 2) {
        border-top: 1px solid hsla(240, 2%, 81%, 0.5);
      }

      &Disabled {
        color: #8d8e93;
        pointer-events: none;
      }
    }
  }

  .isCheck {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    svg {
      color: #17a2c6;
    }

    &PositionTop {
      top: 10px;
      transform: none;
    }
  }
}

.a {
  cursor: pointer;
  color: #8e8e93;
  text-decoration: underline;

  &:hover, &:focus {
    color: #17a2c6;
    text-decoration: underline;
  }
}

.additionalText {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  gap: 5px;
  color: #8e8e93;
  font-weight: 600;
}

.changeLanguage {
  font-size: 14px;
  line-height: 1.43;
  color: #8e8e93;
  text-align: center;
}


.flagSelector {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 28px;

  cursor: pointer;

  &_disabled {
    cursor: default;
  }

  &FlagAll {
    display: block;
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    width: 28px;
    height: 28px;
    margin-left: -14px;
    margin-top: -14px;
  }

  .flagIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 21px;
    height: 15px;
    margin-left: -10px;
    margin-top: -7px;
  }
}

.switcher {
  display: flex;
  align-items: center;

  &Label {
    font-size: 16px;
    line-height: 1.75;
    margin-right: 10px;

    &After {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  &Btn {
    width: 48px;
    min-width: 48px;
    height: 28px;
    padding: 2px;
    margin-left: auto;
    border-radius: 14px;
    background-color: #e6e6e7;
    position: relative;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.5s;

    &Inner {
      position: relative;
    }

    &On,
    &PreOn {
      background-color: #17a2c6;
    }

    &PreOff {
      background-color: #e6e6e7;
    }

    &Handle {
      width: 24px;
      height: 24px;
      border-radius: 20px;
      background-color: #fff;
      position: absolute;
      top: 0;
      right: 100%;
      transform: translateX(100%);
      transition: all 0.5s;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15),
      0 1px 1px rgba(0, 0, 0, 0.16),
      0 3px 1px rgba(0, 0, 0, 0.1);
    }

    &PreOn &Handle,
    &On &Handle {
      right: 0;
      transform: translateX(0);
    }

    &PreOff &Handle {
      right: 100%;
      transform: translateX(100%);
    }
  }

  &Box {
    padding: 10px 15px;
    border-radius: 16px;
    background-color: #f6f6f6;

    .switcherLabel {
      margin-right: 30px;
      line-height: 1.25;

      &After {
        margin-right: 0;
        margin-left: 30px;
      }
    }
  }

  &Green {
    .switcherBtnOn,
    .switcherBtnPreOn {
      background-color: #4cd964;
    }
  }

  &Disabled {
    .switcherBtn {
      cursor: default;
    }
  }

  &.lg {
    .switcherBtn {
      width: 60px;
      min-width: 60px;
      height: 30px;
      border-radius: 20px;

      &Handle {
        width: 26px;
        height: 26px;
        border-radius: 50%;
      }
    }

    &.switcherBox {
      padding: 15px 15px;
    }
  }

  &.bold {
    .switcherLabel {
      font-weight: 600;
    }
  }
}

// Single Line
.firstInSingleLine {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.lastInSingleLine {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.middleInSingleLine {
  border-radius: 0 !important;
}

.singleInSingleLine {
  //
}

// Multi lines - TOP Line
.firstInTopLine {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.lastInTopLine {
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.middleInTopLine {
  border-radius: 0 !important;
}

.singleInTopLine {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

// Multi lines - BOTTOM Line
.firstInBottomLine {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.lastInBottomLine {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.middleInBottomLine {
  border-radius: 0 !important;
}

.singleInBottomLine {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

// Multi lines - MIDDLE Line
.firstInMiddleLine {
  border-radius: 0 !important;
}

.lastInMiddleLine {
  border-radius: 0 !important;
}

.middleInMiddleLine {
  border-radius: 0 !important;
}

.singleInMiddleLine {
  border-radius: 0 !important;
}

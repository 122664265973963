.rewind {
  display: flex;
  align-items: center;

  &Control {
    width: 28px;
    height: 28px;
    cursor: pointer;

    &Inactive {
      opacity: 0.25;
      cursor: auto;
    }

    &Forward {
      transform: rotate(180deg);
    }
  }

  &Selected {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    width: 150px;
    text-align: center;

    &IsDay {
      width: 75px;
    }
  }
}

.controlButtonPrev, .controlButtonNext {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  width: 57px;
  border-radius: 24px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.13);

  transition: 0.6s background-color;

  &:before {
    display: block;
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-size: 42px 42px;
    background-repeat: no-repeat;
    transition: 0.6s opacity;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 991.98px) {
    position: relative;
    margin-top: 10px;
    margin-bottom: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    display: inline-block;
    height: 40px;
    width: calc(50% - 15px);
    top: initial;
    bottom: initial;

    border-radius: 16px;

    &:before {
      opacity: 1;
    }
  }
}

.controlButtonPrev {
  left: -57px;

  &:before {
    background-image: url("~ziphy-web-shared/basic/assets/images/ui/prev-white.svg");
  }

  @media (max-width: 991.98px) {
    left: 10px
  }
}

.controlButtonNext {
  right: -57px;

  &:before {
    background-image: url("~ziphy-web-shared/basic/assets/images/ui/next-white.svg");
  }

  @media (max-width: 991.98px) {
    margin-left: 30px;
    right: 10px;
  }
}

.controlButtonDisabled {
  cursor: default;

  &:before {
    opacity: 0.2;
  }
}

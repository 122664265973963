.wrapper {
  width: 100%;
  position: relative;
}

.unFocusedWrapper {
  position: absolute;
  max-width: calc(100% - 30px);
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  color: #000000;
  display: flex;

  :global(.tsform-item--fw-400) & {
    font-weight: 400;
  }

  .text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 1px;
  }

  .meta {
    position: relative;
    color: #8e8e93;
    flex: 1 0 auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:before {
      display: inline-block;
      content: ' • ';
      width: 16px;
      text-align: center;
    }
  }
}

.ddWrapper {
  position: fixed;
  z-index: 5;
  max-height: 365px;
}

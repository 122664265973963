.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-right: 1px solid #e1e1e2;

  width: 260px;
  left: 0;

  display: flex;
  flex-direction: column;
  padding: 30px 10px;
  background-color: #f9f9f9;

  .body {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }
}

.rating-stars {
  max-height: 40px;

  &-group {
    display: inline-block;
    max-height: 39px;
  }

  &-item {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 40px;
    cursor: pointer;

    padding-left: 7px;
    padding-right: 8px;

    &:first-child {
      margin-left: -7px;
    }

    &:before, &:after {
      display: block;
      content: '';
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-size: cover;
    }

    &:before {
      background: url("~ziphy-web-shared/basic/assets/images/ui/star-empty.svg") no-repeat center;
      opacity: 0.11;
    }

    &:after {
      background: url("~ziphy-web-shared/basic/assets/images/ui/star.svg") no-repeat center;
      opacity: 0;
    }

    &--active {
      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  &-group:hover {
    .rating-stars-item {
      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }

    .rating-stars-item:hover ~ .rating-stars-item {
      &:before {
        opacity: 0.11;
      }

      &:after {
        opacity: 0;
      }
    }
  }

  &-meta {
    color: #8e8e93;
    text-align: center;
  }
}

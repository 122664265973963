.patientsList {
  &Item {
    display: block;
    color: #000000;

    &:hover,
    &:focus {
      color: #000000;
    }

    & + & {
      margin-top: 10px;
    }
  }

  .deceased {
    &Value {
      display: inline;
      opacity: 0.2;
    }
  }
}

.patientsList.isModal {
  .patientsListItem + .patientsListItem {
    margin-top: 0;
  }
}

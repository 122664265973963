.item {
  display: flex;
  padding: 15px;
  border-radius: 16px;
  background-color: #f6f6f6;
  border: solid 2px #f6f6f6;
  color: inherit;

  transition: background-color 0.3s ease-in-out,
  border-color 0.3s ease-in-out;

  cursor: pointer;

  &:hover,
  &:focus {
    background-color: #f0f0f0;
    border-color: #f0f0f0;
    color: inherit;
  }

  line-height: 1.5;

  & + & {
    margin-top: 10px;
  }

  &Active {
    &,
    &:hover {
      border-color: #17a2c6;
    }
  }
}

.table {
  display: flex;
  width: 100%;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  };

  .tableItem {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0;
  }

  .dateBlock {
    flex-basis: 35%;

    .date {
      font-weight: 600;
    }

    @media (max-width: 1200px) {
      flex-basis: 42%;
    };

    @media (max-width: 992px) {
      flex-basis: 100%;
      flex-direction: row;
      justify-content: space-between;
    }

  }

  .typeBlock {
    flex-basis: 30%;
    margin-left: 15px;
    overflow: hidden;

    .type {
      font-weight: 600;

      &Label {
        font-weight: 400;
        color: #8E8E93;
        white-space: nowrap;

        &:before {
          content: '\2022';
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      @media (max-width: 992px) {
        font-weight: 400;
      }
    }

    .officeText {
      display: flex;
      max-width: 50%;
      width: fit-content;
      word-break: break-word;

      @media (max-width: 992px) {
        max-width: 100%;
      }

      @media (min-width: 1200px) {
        max-width: 100%;

        &Name {
          max-width: fit-content;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    @media (max-width: 1200px) {
      display: flex;
      order: 1;
      width: 100%;
      flex-basis: auto;
      margin-top: 10px;
      margin-left: 0;

      &Office {
        display: flex;
        color: #8E8E93;

        .officeText + .officeText {
          margin-left: 15px;
        }
      }
    };

    @media (max-width: 992px) {
      order: 0;
    }
  }

  .durationBlock {
    flex-basis: 15%;
    margin-left: 15px;
    justify-content: space-between;

    .duration {
      font-weight: 600;
    }

    .timeDiff {
      color: #8E8E93;
    }

    @media (max-width: 1200px) {
      margin-left: 10px;
      flex-grow: 1;
    };
  }

  .statusBlockItem {
    flex-basis: 28%;
    margin-left: 15px;
    text-align: end;

    @media (max-width: 1200px) {
      margin-left: 10px;
      flex-basis: 30%;
    };
  }

  .statusBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .idBlock {
    margin-top: 10px;
    font-size: 12px;
    color: #8E8E93;
  }

  @media (max-width: 992px) {
    .statusBlock {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .date {
      display: flex;
      flex-direction: column;
    }
  }
}

.cost {
  text-align: center;

  .textMeta {
    margin-top: -2px;
    text-decoration: line-through;
  }

  @media (max-width: 992px) {
    white-space: nowrap;

    &:before {
      content: '\2022';
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 4px 10px;
  text-align: right;
  background-color: #0000000d;
  border-radius: 20px;
  color: #8e8e93;
  white-space: nowrap;
  position: relative;
  margin-bottom: 5px;

  .textMeta {
    margin-top: -2px;
  }

  @media (max-width: 767.98px) {
    font-size: 13px;
    margin-bottom: 0;
  }

  @media (max-width: 575.98px) {
    width: 100%;
    max-width: 100%;
    text-align: left;

    position: relative;
    top: initial;
    right: initial;
  }

  & + .indicators {
    white-space: nowrap;
    word-break: keep-all;
    color: #e73737;

    @media (max-width: 767.98px) {
      font-size: 13px;
    }
  }
}

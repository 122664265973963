.wrapper {
  display: flex;
  align-items: center;

  text-transform: uppercase;

  font-size: 13px;
  font-weight: normal;
  line-height: 1.54;
}

.label {
  color: #8e8e93;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-left: auto;

  .btn {
    color: #17a2c6;
    transition: color 0.3s ease-in-out;
    cursor: pointer;

    &:hover, &:focus {
      color: #1386a4;
      text-decoration: none;
    }
  }
}

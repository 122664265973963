.g-map {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px;
  overflow: hidden;
  background: #f6f6f6;

  &-inner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    & > div {
      background: #f6f6f6 !important;
    }

    iframe + div {
      border: none !important;
    }
  }

  &--static * {
    cursor: default;
  }

  &--active {
    border: 2px solid #17a2c6;
  }
}

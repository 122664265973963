.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.title {
  flex: 0;
  order: 0;
  margin-bottom: 20px;
  margin-right: auto;
}

.buttons {
  order: 1;
  padding-left: 10px;
  margin-bottom: 20px;
}

.list {
  order: 2;
  min-width: 100%;
}

.isModal {
  .buttons {
    order: 3;
    margin-top: 20px;
    margin-bottom: initial;
  }
}

.badge {
  position: relative;
  display: inline-flex;
  padding: 9px;
  border-radius: 10px;
  border: solid 1px rgba(206, 206, 208, 0.5);
  font-size: 13px;
  font-weight: bold;
  line-height: 1.54;

  .badge-group--sm & {
    padding: 5px 10px;

    .badge-close {
      width: 30px;
      min-width: 30px;
      height: 30px;
      margin: -5px -10px -5px 0;
    }
  }

  &-value {
    display: inline-block;
    margin-left: 5px;
  }

  &-close {
    position: relative;
    width: 40px;
    height: 40px;
    margin: -10px -10px -10px -5px;
    border-radius: 0 10px 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--primary {
    color: #17a2c6 !important;
    border-color: #17a2c6;

    .badge-close svg g g {
      fill: #17a2c6;
    }
  }

  &--primary-fill {
    color: #ffffff;
    background-color: #17a2c6;
    border-color: #17a2c6;

    .badge-close svg g g {
      fill: #fff;
    }
  }

  &--light-blue {
    color: #17a2c6 !important;
    background-color: rgba(23, 162, 198, 0.1);
    border-color: rgba(23, 162, 198, 0.1);
  }

  &--gray {
    color: #8e8e93;
    border-color: #e1e1e2;
    font-weight: 400;
    padding: 8px 10px;

    .badge-close svg g g {
      fill: #17a2c6;
    }
  }

  &--gray-fill {
    background-color: #f6f6f6;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: #848484 !important;
    border: none;
  }

  &--dark-gray-fill {
    background-color: #8e8e93;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: #ffffff !important;
    border: none;
  }

  &--white {
    color: #8e8e93 !important;
    border-color: #fff;
    background-color: #fff;
    font-weight: 400;
    padding: 8px 10px;

    .badge-close svg g g {
      fill: #17a2c6;
    }
  }

  &--round {
    height: 40px;
    border-radius: 20px;
    padding: 8px 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: #ffffff;
  }

  &--sm {
    max-height: 20px;
    padding: 0 10px;
    border-radius: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #ffffff;
  }

  &--lg {
    align-items: center;
    height: 48px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 40px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 700;

    .badge-close {
      width: 43px;
      min-width: 43px;
      height: 46px;
      margin: 0 -14px 0 5px;
      padding-right: 15px;

      &:before {
        width: 28px;
        height: 28px;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
    }
  }

  &--green {
    background-color: #4cd964;
    border: none;
  }

  &--purple {
    background-color: #954cf3;
    border: none;
  }

  &--info {
    font-weight: normal;
    border-color: #e7f5f9;
    background-color: #e7f5f9;
  }

  &--clickable {
    cursor: pointer;
  }
}

.container {
  display: flex;
  flex-direction: column;

  max-height: 180px;
  max-width: 340px;
  width: 100%;
  height: 100%;

  padding: 15px 16px;

  background-color: rgba(246, 246, 246, 1);
  border-radius: 16px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin-bottom: 10px;
}

.chart {
  position: relative;
  width: 100%;
  height: 120px; // 180px(container) - 30px(paddings) - 20px(title) - 10px(titleMargin)
}

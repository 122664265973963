.timelineChart {
  border-radius: 16px;
  border: 1px solid #e6e6e7;
  padding: 5px 15px 10px;
  margin-bottom: 30px;
  height: 200px;
}

.formGroup {
  max-width: 240px;
  @media (max-width: 576px) {
    max-width: 100%;
  }
}

.toRight {
  margin-left: auto;
}
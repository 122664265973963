@import '@assets/styles/mixins';

.help {
  @media (max-width: 991.98px) {
    padding: 0 15px;
  }

  &Header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    @media (max-width: 991.98px) {
      align-items: baseline;
    }

    &Title {
      font-weight: bold;
      font-size: 22px;
      line-height: 1.36;

      @media (max-width: 991.98px) {
        font-size: 18px;
        line-height: 1.27;
      }
    }

    &TypeSwitcher {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      text-align: right;
      color: #17a2c6;
      cursor: pointer;
    }
  }

  &Item {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 10px 68px 10px 20px;
    border-radius: 16px;
    background-color: #f6f6f6;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    color: #000000;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    height: 68px;
    user-select: none;
    cursor: pointer;

    &--short {
      padding-left: 20px;
    }

    &Body {
      display: flex;
      flex-wrap: wrap;
      column-gap: 5px;
      width: 100%;

      .texClamp2 {
        width: 100%;
        display: inline;
        margin-bottom: 2px;
        @include ellipsis;
      }
    }

    :global {
      .ct_actions {
        z-index: 3;
      }
    }

    &:hover,
    &:focus {
      //color: #000000;
      //background-color: #f0f0f0;
    }

    &Icon {
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &Button {
      outline: none;
      border: none;
      justify-content: center;
      align-items: center;
      padding-left: 40px;
      padding-right: 40px;
      color: #17a2c6;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      cursor: pointer;

      &:hover,
      &:focus {
        color: #17a2c6;
        outline: none;
      }

      @media (max-width: 991.98px) {
        padding: 10px 20px;
      }
    }
  }
}

.disabled {
  opacity: 0.25;
  pointer-events: none;
}

.addAddress {
  :global {
    .modal-content {
      overflow: hidden;
      min-height: 550px;

      @media (max-width: 991.98px) {
        min-height: 100%;
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;

      @media (min-width: 992px) {
        padding-bottom: 30px;
      }
    }
  }

  &Map {
    position: relative;
    flex: 1;
    min-height: 361px;
    transition: min-height 400ms ease;

    &Details & {
      min-height: 206px;
    }
  }
}

.wrapper {
  padding-right: 15px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;

  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }

  .btn {
    min-width: fit-content;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 700;
    padding: 16px 15px;
    line-height: 16px;
    white-space: nowrap;

    &.search {
      margin-left: 15px;
      padding: 0;
      min-width: 48px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #f6f6f6 url('~ziphy-web-shared/basic/assets/images/ui/search.svg') no-repeat center;
      background-size: 28px 28px;
    }
  }
}

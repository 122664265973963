.devButton {
  padding: 5px 5px;
  border-radius: 16px !important;
  width: calc(50% - 5px);
  margin-top: -10px;
  margin-bottom: 10px;
  text-transform: capitalize;

  & + & {
    margin-left: 10px;
  }
}

.topBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;

  &Label {
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 2px;
  }

  &Clear {
    padding-left: 8px;
    font-size: 13px;
    line-height: 1.54;
    text-align: right;
    color: #18a2c6;
    cursor: pointer;
    flex: auto;
  }
}

.multiselect {
  background: #fff;
  border: 1px solid #e6e6e7;
  cursor: pointer;
  outline: none;
  padding: 10px 15px;
  position: relative;
  transition: border-color 0.2s ease;
  width: 100%;

  &Error {
    border-color: #ff3b30 !important;
  }

  &Readonly {
    cursor: default;
    pointer-events: none;
    background: #f6f6f6;
  }

  &Open {
    //border-color: #17a2c6;
  }

  &Preview {
    text-align: left;
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-right: 28px;
  }

  &Label {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #8e8e93;
    position: absolute;
    left: 15px;
    top: 10px;
  }

  &Selected {
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000;

    //not used now
    &Count {
      position: absolute;
      right: 45px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: #8e8e93;
    }
  }

  &Placeholder {
    color: #d1d1d6 !important;
  }

  &Arrow {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 55%;
    transform: translateY(-50%);

    & svg {
      width: 24px;
      height: 24px;
      opacity: 0.2;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      transition: opacity 0.15s ease,
      -webkit-transform 0.15s ease;
      transition: opacity 0.15s ease,
      transform 0.15s ease;
      transition: opacity 0.15s ease,
      transform 0.15s ease,
      -webkit-transform 0.15s ease;
    }

    &Open {
      top: 50%;

      svg {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
      }
    }
  }
}

.fw-400 {
  font-weight: 400 !important;
}

.sm {
  height: 40px;
  border-radius: 10px;
}

.lg {
  height: 62px;
  border-radius: 16px;

  .multiselectLabel {
    top: 9px;
  }

  .multiselectSelected {
    position: relative;
    width: calc(100% - 28px);
  }
}

.md {
  height: 48px;
  border-radius: 10px;

  .multiselectPreview {
    align-items: center;
  }
}

.gray {
  background: #f2f3f3;
  border: none;
}

@import '@assets/styles/mixins';

.nearestAppt {
  position: fixed;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  height: 88px;
  backdrop-filter: blur(10px);
  box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.09);
  background-color: rgba(249, 249, 249, 0.75);
  transform: translateY(100%);
  transition: transform 0.2s 0.2s ease-in-out;

  &Visible {
    transform: translateY(0%);
  }

  & > :global(.container) {
    max-width: 1200px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &Wrapper {
    display: flex;
    transition: opacity 0.2s 0.2s ease-in-out;

    &Hidden & {
      opacity: 0;
    }
  }

  &Logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 58px;
    height: 58px;
    margin-right: 15px;
    background-color: #17a2c6;
    border-radius: 16px;

    img {
      margin-left: 1px;
      max-height: 38px;
    }
  }

  &Content {
    flex: 1;
    display: flex;
    align-items: center;

    &Text {
      flex: 1;
      @include line-clamp(2);
    }

    &Buttons {
      margin-left: 15px;
    }

    &Btn {
      position: relative;
      display: block;
      padding: 8px;
      margin: -8px;

      transition: opacity 0.3s ease-in-out;
      opacity: 0.25;

      &:hover {
        opacity: 1;
      }

      &:before {
        display: block;
        content: '';
        width: 28px;
        height: 28px;
        background-image: url('~ziphy-web-shared/basic/assets/images/ui/up.svg');
      }
    }
  }
}

.item {
  position: relative;
  overflow: hidden;

  padding: 15px;
  border-radius: 16px;
  background-color: #F6F6F6;

  transition: background-color 0.3s ease-in-out;
  height: 130px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
}

.header {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 5px;
  display: flex;
  flex: 1 1 100%;
  min-width: 1px;


  &Text {
    color: #8e8e93;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .badge {
    margin-left: 5px;
    line-height: inherit;
    margin-top: -1px;
    margin-bottom: -1px;
    padding: 0 10px;
  }

}

.body {
  .title {
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }
}

.deleteButton {
  content: '';
  display: block;
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 28px;
  height: 28px;
  background-image: url('~ziphy-web-shared/basic/assets/images/ui/trash.svg');
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;

  .view:hover & {
    opacity: 0.2;
    pointer-events: initial;
  }
}

.checkbox {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.selectModal {
  &Group {
    margin-top: -10px;

    :global(.modal-dialog--header-bordered) & {
      margin-top: 0;
    }
  }

  .item {
    position: relative;
    padding: 10px 30px;

    margin-left: -30px;
    margin-right: -30px;

    font-weight: 600;

    display: flex;
    align-items: center;
    gap: 15px;

    @media (max-width: 991.98px) {
      padding: 10px 15px;
      margin-left: -15px;
      margin-right: -15px;
    }

    &:hover {
      cursor: pointer;
    }

    .checkContainer {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #f2f3f3;

      .icon {
        opacity: 0;

        g {
          fill: #ffffff;
        }
      }
    }

    &Active {
      .checkContainer {
        background-color: #17a2c6;

        .icon {
          opacity: 1;
        }
      }
    }

    &Child {
      margin-left: -15px;
      font-weight: normal;

      @media (max-width: 991.98px) {
        margin-left: 0;
      }
    }

    &ChildActive {
      .checkContainer,
      .icon {
        width: 14px;
        height: 14px;
      }
    }

    &ParentActive {
      .checkContainer {
        opacity: 0.33;
      }
    }
  }
}

.tsform {
  position: relative;
}

.tsform-item {
  & + & {
    margin-top: 10px;
  }

  &[class*='col'] {
    margin-top: 0;
  }

  &-inner {
    position: relative;
    display: block;
    margin: 0;
  }

  .tsform-header {
    font-size: 13px;
    display: flex;
    font-weight: normal;
    line-height: 1.54;
    margin-bottom: 2px;

    .tsform-label {
      display: block;
      font-weight: 600;
      color: #000000;
      margin-right: auto;
      margin-bottom: 0;
      user-select: none;

      &-clamp-1 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &-clamp-2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    &-action {
      margin-left: 10px;
      text-align: right;
      color: #18a2c6;
      cursor: pointer;
      transition: color 0.3s ease-in-out;

      &:hover,
      &:focus {
        color: #1386a4;
      }
    }
  }

  .tsform-body {
    position: relative;
    border: 1px solid #e6e6e7;
    border-radius: 10px;
    padding: 9px 15px;
  }

  .tsform-field {
    input,
    select,
    textarea {
      display: block;
      width: 100%;
      padding: 0;
      border: none;
      border-radius: 0;
      outline: none;
      font-size: 16px;
      line-height: 1.25;
      font-weight: normal;
      color: #000000;
      background: transparent;

      &--placeholder,
      &::placeholder {
        color: #d1d1d6;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }

      &[type='text']::-ms-clear {
        display: none;
        width: 0;
        height: 0;
      }

      &[type='text']::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
      }

      &[type='search']::-webkit-search-decoration,
      &[type='search']::-webkit-search-cancel-button,
      &[type='search']::-webkit-search-results-button,
      &[type='search']::-webkit-search-results-decoration {
        display: none;
      }
    }

    textarea {
      line-height: 1.5;
    }

    & .phone_number {
      border-bottom: initial !important;
    }

    & .phone_number-flag {
      padding: 0 5px 0 0 !important;
    }
  }

  .tsform-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &--calendar {
      width: 28px;
      height: 28px;
      opacity: 0.2;
    }
  }

  .tsform-field-clear {
    order: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    background: #b5b6b6 url('~ziphy-web-shared/basic/assets/images/ui/close-white.svg') no-repeat center;
    background-size: cover;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
    right: 10px;
    width: 20px;
    min-width: 20px;
    height: 20px;
  }

  &:hover .tsform-field-clear {
    opacity: 1;
  }
}

.tsform-item-remove_button {
  opacity: 0.25;
  width: 28px;
  height: 28px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  background: url('~ziphy-web-shared/basic/assets/images/ui/trash.svg') no-repeat center;
  margin-left: 10px;

  &:hover {
    opacity: 1;
  }
}

.tsform-item--with_remove {
  position: relative;
  display: flex;
  align-items: center;

  .tsform-item-inner {
    flex: 1;
  }
}

.tsform-item--lg {
  .tsform-item-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #e6e6e7;
    border-radius: 16px;
  }

  &.tsform-item--select,
  &.tsform-item--input {
    .tsform-item-inner {
      min-height: 50px;
      max-height: 62px;
    }
  }

  .tsform-header {
    padding: 9px 15px 0;

    .tsform-label {
      font-weight: normal;
      color: #8e8e93;
    }
  }

  .tsform-body {
    position: initial;
    border: 0;
    border-radius: 0;
  }

  .tsform-header + .tsform-body {
    padding-top: 0;
  }

  .tsform-field-clear {
    right: 15px;
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
}

.tsform-item--gray {
  .tsform-body {
    background-color: #f6f6f6;
  }

  .tsform-icon {
    top: initial;
    transform: initial;
    bottom: 6px;
  }
}

.tsform-item--value-bold {
  .tsform-body {
    .tsform-field {
      input {
        font-weight: 600;
      }

      .phone_number-flag {
        font-weight: 600;
      }
    }
  }

  .tsform-icon {
    top: initial;
    transform: initial;
    bottom: 6px;
  }
}

.tsform-item--lg.tsform-item--gray {
  .tsform-body {
    background-color: transparent;
  }

  .tsform-item-inner {
    background-color: #f6f6f6;
  }
}

.tsform-item--gray-without-border {
  .tsform-body {
    background-color: #f6f6f6;
    border-color: #f6f6f6;
  }

  .tsform-icon {
    top: initial;
    transform: initial;
    bottom: 6px;
  }
}

.tsform-item--lg.tsform-item--gray-without-border {
  .tsform-body {
    background-color: transparent;
    border-color: transparent;
  }

  .tsform-item-inner {
    background-color: #f6f6f6;
    border-color: #f6f6f6;
  }
}

.tsform-item--readonly {
  .tsform-item-inner {
    background-color: #f6f6f6;
    cursor: default;
  }

  .tsform-field {
    input,
    select,
    textarea {
      cursor: default;
    }
  }
}

.tsform-item--fw-400 {
  input,
  select,
  textarea {
    font-weight: 400 !important;
  }
}

@mixin reset_middle_border {
  &:not(:first-child):not(:last-child),
  &:first-child:not(:last-child) {
    .multiselect,
    .tsform-body,
    .tsform-item-inner {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-color: transparent;
    }
  }

  &:not(:first-child):not(:last-child),
  &:last-child:not(:first-child) {
    .multiselect,
    .tsform-body,
    .tsform-item-inner {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
    }

    &.tsform-item--gray-without-border {
      .multiselect,
      .tsform-body,
      .tsform-item-inner {
        border-left-color: #e6e6e7;
      }
    }
  }
}

.tsform-join-group {
  display: flex;
  position: relative;
  margin-left: 0;
  margin-right: 0;

  & > [class*='col'] {
    padding-left: 0;
    padding-right: 0;
  }

  .tsform-item {
    &:not([class*='col']) {
      margin-top: 0;
      flex: 1 1 auto;
      @include reset_middle_border;
    }
  }

  [class*='col']:not([class*='col-lg']):not([class*='col-md']):not([class*='col-sm']) {
    @include reset_middle_border;
  }

  [class*='col-lg'],
  .tsform-item.col-lg {
    @media (min-width: 992px) {
      @include reset_middle_border;
    }
  }

  @media (max-width: 991.98px) {
    [class*='col-lg'] + [class*='col-lg'],
    .tsform-item.col-lg + .tsform-item.col-lg {
      margin-top: 10px;
    }
  }

  [class*='col-md'],
  .tsform-item.col-md {
    @media (min-width: 768px) {
      @include reset_middle_border;
    }
  }

  @media (max-width: 767.98px) {
    [class*='col-md'] + [class*='col-md'],
    .tsform-item.col-md + .tsform-item.col-md {
      margin-top: 10px;
    }
  }

  [class*='col-sm'],
  .tsform-item.col-sm {
    @media (min-width: 576px) {
      @include reset_middle_border;
    }
  }

  @media (max-width: 575.98px) {
    [class*='col-sm'] + [class*='col-sm'],
    .tsform-item.col-sm + .tsform-item.col-sm {
      margin-top: 10px;
    }
  }

  &--checkbox {
    flex-wrap: wrap;
    margin-bottom: -10px;
    margin-right: -5px;
    margin-left: -5px;

    & .form-check {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 10px;

      @media (max-width: 767.98px) {
        flex-grow: 1;
        flex-basis: 100%;

        .form-check-label {
          white-space: initial;
        }
      }
    }

    .modal & {
      .ct_actions {
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        &-btn {
          opacity: 1;
        }
      }

      .ct_actions + .form-check {
        margin-right: 53px;
      }

      margin-bottom: 20px;
    }
  }
}

.tsform-item--cursor-pointer:not(.tsform-item--readonly) {
  .tsform-item-inner {
    cursor: pointer;
  }

  .tsform-field {
    input,
    select,
    textarea {
      cursor: pointer;
    }
  }

  .tsform-icon {
    top: initial;
    transform: initial;
    bottom: 6px;
  }
}

.tsform-item--error {
  .tsform-body,
  .tsform-item-inner {
    border-color: #ff3b30 !important;
    border-right-width: 1px !important;
    z-index: 1;
  }
}

.tsform-group--bg {
  .tsform-item {
    border-bottom: 1px solid rgba(206, 206, 208, 0.5);
    padding: 6px 0;
  }

  .tsform-header,
  .tsform-body {
    padding: 0;
  }
}

.tsform-item--unfocused {
  .tsform-field {
    input,
    select,
    textarea {
      opacity: 0;
    }
  }
}

.wrapper {
  position: relative;
  margin-bottom: 30px;

  @media (max-width: 991.98px) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.practice {
  margin-bottom: 10px;
}

.group {
  display: flex;

  &Item {
    white-space: nowrap;
  }

  &Item + &Item {
    margin-left: 10px;
  }

  @media (max-width: 991.98px) {
    margin-left: -15px;

    .groupItem:first-child {
      margin-left: 15px;
    }

    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Chrome, Safari and Opera */
    }
  }
}

.badgeLabel {
  opacity: 0.5;
  margin-right: 5px;
}
.day-picker-calendar {
  height: 330px;

  & > .header {
    .day-week {
      display: flex;
      margin-bottom: 20px;

      .cell {
        flex: 1;
        text-align: center;
        font-size: 16px;
        line-height: 1.25;
        color: #8e8e93;
        user-select: none;
      }
    }

    .name-month {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 600;
    }
  }

  & > .body {
    .row-week {
      display: flex;
      justify-content: space-around;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .cell {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin-right: 1px;
        font-size: 16px;
        border-radius: 24px;
        background-color: #ffffff;
        cursor: pointer;
        user-select: none;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

        @media (max-width: 767.98px) {
          font-size: 18px;
        }

        &:nth-child(7n + 7) {
          margin-right: 0;
        }

        &:hover {
          background-color: #f6f6f6;
        }

        &.cell-selected {
          font-weight: 600;
          color: #ffffff;
          background-color: #17a2c6;
        }

        &.cell-current {
          font-weight: 600;
          color: #17a2c6;

          &.cell-selected {
            color: #ffffff;
          }
        }

        &.cell-locked {
          color: #cccccc;
          cursor: default;

          &.cell-selected {
            color: #ffffff;
            background-color: #cccccc;

            &.cell-current {
              color: #ffffff;
            }
          }

          &.cell-current {
            color: #17a2c6;
            opacity: 0.5;
          }

          &:not(.cell-selected):hover {
            background-color: #ffffff;
          }
        }

        &.cell-other-month {
          color: #8e8e93;

          &.cell-selected {
            color: #ffffff;
            background-color: #8e8e93;
          }
        }

        &.cell-hidden {
          cursor: default;

          &:hover {
            background-color: #ffffff;
          }
        }
      }
    }
  }
}

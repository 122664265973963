.appt_status {
  display: flex;

  &-item {
    color: #d1d1d6;
    margin-bottom: 0;
    white-space: nowrap;

    &--active {
      color: #000000;
    }
  }

  &-delimiter {
    width: 40px;
    min-width: 60px;
    height: 1px;
    align-self: center;
    margin-left: 10px;
    margin-right: 10px;
    background-color: rgba(206, 206, 208, 0.5);

    @media (max-width: 991.98px) {
      width: 15px;
      min-width: 30px;
    }
  }
}

.appt_map {
  position: relative;
  padding: 30px;
  min-height: 350px;
  margin-bottom: 30px;

  border-radius: 16px;
  //overflow: hidden;
  background: #f6f6f6;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 991.98px) {
    padding: 15px;
    overflow: hidden;

    .appt_map-map {
      margin-bottom: 90px;

      .g-map {
        top: -25px;
      }
    }

    .appt_map-map + .appt_control {
      margin-top: 210px;
    }
  }

  .appt_control {
    border-radius: 16px;
    background: #ffffff;
    margin-bottom: 0;
    padding: 10px 20px 20px;
    min-height: 96px;

    &-address {
      margin-top: 10px;
    }

    &-buttons {
      margin-top: 10px;
    }

    @media (max-width: 991.98px) {
      padding: 5px 15px 15px;
      flex-direction: column;
    }
  }

  &-completed_marker {
    position: relative;
    width: 100px;
    min-width: 100px;
    height: 100px;
    margin-bottom: 30px;
    border-radius: 100%;
    background-color: #07dd89;
    display: flex;
    align-items: center;
    justify-content: center;

    svg g {
      fill: #ffffff;
    }

    @media (max-width: 991.98px) {
      width: 70px;
      min-width: 70px;
      height: 70px;
      margin-bottom: 40px;
    }
  }
}

.appt_control {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;

  &-address {
    margin-right: auto;
    padding-right: 15px;

    font-size: 18px;
    line-height: 1.56;
    color: #000000;

    @media (max-width: 991.98px) {
      font-size: 16px;
    }

    &-label {
      font-weight: bold;
    }
  }

  &-buttons {
    position: relative;
    margin-bottom: -10px;

    @media (max-width: 991.98px) {
      align-self: flex-start;
      min-width: calc(100% + 10px);
    }

    .dropdown,
    & > .btn {
      margin-bottom: 10px;
    }

    .dropdown {
      display: inline-block;
      vertical-align: middle;

      @media (max-width: 991.98px) {
        display: none;
      }
    }

    & > .btn {
      @media (max-width: 991.98px) {
        display: block;
      }
    }

    .btn--hidden {
      display: none;

      @media (max-width: 991.98px) {
        display: block;
        min-width: calc(100% - 10px);
      }
    }
  }

  &-address + &-buttons {
    @media (max-width: 991.98px) {
      margin-top: 10px;
    }
  }
}

.appt_details_root {
  .appt_video {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    background: #f6f6f6;
    margin-bottom: 30px;
  }
}

.appt_video_target {
  position: relative;
  border-radius: 16px;
  background: #f6f6f6;
  //background: #bbb;
  margin-bottom: 30px;
}

.appt_contacts {
  margin-bottom: 30px;

  &--disabled {
    @media (min-width: 768px) {
      display: none;
    }
  }

  &-title {
    margin-bottom: 10px;
  }

  & > .row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;

    [class*='col'] {
      margin-bottom: 10px;

      &.disabled {
        @media (min-width: 768px) {
          display: none;
        }
      }

      flex-basis: 33.333333%;
      max-width: 33.333333%;

      &.all-4 {
        &.visible-4 {
          flex-basis: 25%;
          max-width: 25%;
        }

        @media (max-width: 767.98px) {
          flex-basis: 25%;
          max-width: 25%;
        }
      }
    }

    @media (max-width: 767.98px) {
      margin-left: -5px;
      margin-right: -5px;

      & > [class*='col'] {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  &-item {
    position: relative;
    display: flex;
    padding: 15px;
    border-radius: 16px;
    background-color: #f6f6f6;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    text-align: left;
    min-width: initial;

    &:hover {
      background-color: #f2f3f3;
    }

    .appt_contacts--disabled &,
    [class*='col'].disabled & {
      cursor: default;
      opacity: 0.4;
    }
  }

  &-icon {
    min-width: 40px;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 100%;

    background-repeat: no-repeat;
    background-position: center;
    background-color: #000000;

    &--text {
      background-image: url('~ziphy-web-shared/basic/assets/images/ui/text-message-white.svg');
    }

    &--phone {
      background-image: url('~ziphy-web-shared/basic/assets/images/ui/phone-call-white.svg');
    }

    &--video {
      background-image: url('~ziphy-web-shared/basic/assets/images/ui/video-call-white.svg');
    }
  }

  &-content {
    align-self: center;

    @media (min-width: 768px) {
      width: calc(100% - 50px);
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      color: #000000;

      span {
        display: none;

        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;

        @media (min-width: 768px) {
          &.full {
            display: inline-block;
          }
        }
        @media (min-width: 576px) and (max-width: 767.98px) {
          //@media (max-width: 767.98px) {
          &.short {
            display: inline-block;
          }
        }
        @media (max-width: 575.98px) {
          &.extra_short {
            display: inline-block;
          }
        }
      }
    }

    &-meta {
      font-size: 13px;
      line-height: 1.54;
      color: #8e8e93;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  @media (max-width: 767.98px) {
    &-item {
      flex-direction: column;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
    }

    &-icon {
      width: 28px;
      height: 28px;
      background-color: transparent;
      margin-right: 0;

      &--text {
        background-image: url('~ziphy-web-shared/basic/assets/images/ui/text-message.svg');
      }

      &--phone {
        background-image: url('~ziphy-web-shared/basic/assets/images/ui/phone-call.svg');
      }

      &--video {
        background-image: url('~ziphy-web-shared/basic/assets/images/ui/video-call.svg');
      }
    }

    &-content {
      text-align: center;

      &-title {
        font-size: 13px;
        line-height: 1.54;
      }

      &-meta {
        display: none;
      }
    }
  }
}

.appt_conference {
  margin-bottom: 30px;

  @media (max-width: 991.98px) {
    &:not(.appt_conference--map) {
      display: none;
    }
  }

  &-title {
    margin-bottom: 10px;
  }

  & > .row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;

    [class*='col'] {
      margin-bottom: 10px;
      max-width: 33.333333%;
    }
  }

  .user_photo {
    margin-right: 10px;
  }

  &-item {
    position: relative;
    display: flex;
    padding: 15px;
    border-radius: 16px;
    background-color: #f6f6f6;
    transition: background-color 0.3s ease-in-out;
  }

  &-content {
    align-self: center;

    &-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      color: #000000;
    }

    &-meta {
      font-size: 13px;
      line-height: 1.54;
    }
  }

  &-link {
    align-self: center;
    margin-left: auto;
    padding-left: 10px;
    text-align: right;
    line-height: 20px;
  }
}

.appt_conference--map.appt_conference {
  align-self: stretch;
  margin-bottom: 0;

  @media (min-width: 992px) {
    display: none;
  }

  & > .row {
    flex-direction: column;
    margin: 0;

    [class*='col'] {
      margin: 0;
      padding: 0;
      max-width: 100%;
    }

    [class*='col'] + [class*='col'] {
      border-top: 1px solid rgba(206, 206, 208, 0.5);
    }
  }

  .user_photo {
    display: none;
  }

  .appt_conference-item {
    display: flex;
    padding: 15px 0;
    border-radius: 0;
    background-color: initial;
  }

  .appt_conference-content {
    display: flex;
    flex-grow: 1;

    &-title {
      font-size: 16px;
      line-height: 1.5;
      color: #8e8e93;
      font-weight: normal;
    }

    &-meta {
      font-size: 16px;
      line-height: 1.5;
      margin-left: auto;
      padding-left: 10px;
      color: #8e8e93;
    }
  }

  .appt_conference-link {
    padding-left: 15px;
    font-size: 16px;
    line-height: 1.5;
  }

  @media (max-width: 575.98px) {
    .appt_conference-content {
      &-title {
        font-size: 15px;
      }

      &-meta {
        font-size: 15px;
        padding-left: 10px;
      }
    }

    .appt_conference-link {
      font-size: 15px;
      padding-left: 10px;
    }
  }
}

.appt_note {
  margin-bottom: 30px;

  .alert {
    white-space: pre-line;
  }
}

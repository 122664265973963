.loader {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
}

// loader Modes
.pageWrapper {
  margin-top: 100px;
}

.h84 {
  padding-top: 0;
  padding-bottom: 0;
  height: 84px;

  @media (max-width: 991.98px) {
    height: 76px;
  }
}

.h106 {
  padding-top: 0;
  padding-bottom: 0;
  height: 106px;

  @media (max-width: 991.98px) {
    height: 76px;
  }
}

.h108 {
  padding-top: 0;
  padding-bottom: 0;
  height: 108px;

  @media (max-width: 991.98px) {
    height: 76px;
  }
}

.bgGray {
  border-radius: 16px;
  background-color: #f6f6f6;
}

.absoluteCenter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
}

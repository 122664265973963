.officeItem {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 10px;
  padding: 10px 15px;
  border-radius: 16px;
  background: #f6f6f6;
}

.name {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.phone {
  cursor: pointer;
  color: #17a2c6;
}

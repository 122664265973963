.wrapper {
  position: relative;
  margin-bottom: 30px;
}

.header {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: baseline;

  @media (max-width: 991.98px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .title {
    font-size: 32px;
    line-height: 1.5;
    margin-bottom: 0;

    @media (max-width: 991.98px) {
      font-size: 20px;
    }
  }
}

.body {
  position: relative;
}

.overlay {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
}

.icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  width: 39px;
  min-width: 39px;
  height: 28px;
  border-radius: 6px;

  background: #ffffff no-repeat center;
  background-size: contain;

  &.visa {
    background-image: url("~@assets/images/payments/visa.svg");
  }

  &.mastercard {
    background-image: url("~@assets/images/payments/mastercard.svg");
  }

  &.maestro {
    background-image: url("~@assets/images/payments/maestro.svg");
  }

  &.unionpay {
    background-image: url("~@assets/images/payments/unionpay.svg");
  }

  &.jcb {
    background-image: url("~@assets/images/payments/jcb.svg");
  }

  &.discover {
    background-image: url("~@assets/images/payments/discover.svg");
  }

  &.american-express {
    background-image: url("~@assets/images/payments/american-express.svg");
  }

  &.diners-club {
    background-image: url("~@assets/images/payments/diners-club.svg");
  }
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 10;

  padding: 10px 15px;
  background-color: #ffffff;
  box-shadow: 0 4px 16px 0 #00000066;
  position: fixed;

  @media (min-width: 992px) {
    bottom: 30px;
    left: calc(50% + var(--selection-bar-left-offset, 0) / 2);
    transform: translateX(-50%);
    border-radius: 20px;
  }

  @media (max-width: 991.98px) {
    left: 0;
    right: 0;
    bottom: 0;
  }

}

.label {
  line-height: 1.3;

  @media (min-width: 992px) {
    min-width: 180px;
  }
}

.content {
  margin-left: auto;
}

.closeButton {
  padding: 6px;
  margin: -6px;
  cursor: pointer;
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

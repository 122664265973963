.wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  .inner {
    width: 100%;
    height: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      margin-bottom: 2px;

      .label {
        font-size: 13px;
        font-weight: 600;
        line-height: 1.54;
        margin: 0;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .actionsContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;
        margin-left: auto;

        .action {
          font-size: 13px;
          line-height: 1.54;
          text-align: right;
          color: #18a2c6;
          cursor: pointer;
          transition: color 0.3s ease-in-out;

          &:hover,
          &:focus {
            color: #1386a4;
          }
        }
      }
    }

    .body {
      width: 100%;
      display: flex;
      align-items: center;

      border: 1px solid #e6e6e7;
      background-color: #fff;
      border-radius: 10px;
      padding: 7px 15px;

      .fieldContainer {
        width: 100%;
        display: flex;
        align-items: center;

        &WithClear {
          padding-right: 20px;
        }

        input {
          width: 100%;
          padding: 0;
          border: none;
          border-radius: 0;
          outline: none;
          font-size: 16px;
          line-height: 1.5;
          font-weight: normal;
          color: #000000;
          background: transparent;

          &::placeholder {
            color: #d1d1d6;
          }

          &[type='number'] {
            -moz-appearance: textfield;
          }

          &[type='text']::-ms-clear {
            display: none;
            width: 0;
            height: 0;
          }

          &[type='text']::-ms-reveal {
            display: none;
            width: 0;
            height: 0;
          }

          &[type='search']::-webkit-search-decoration,
          &[type='search']::-webkit-search-cancel-button,
          &[type='search']::-webkit-search-results-button,
          &[type='search']::-webkit-search-results-decoration {
            display: none;
          }
        }

        .fieldActionsContainer {
          display: flex;
          align-items: center;
          gap: 5px;
          margin-right: 5px;

          .action {
            font-size: 13px;
            color: #17a2c6;
            cursor: pointer;
          }
        }
      }

      .icon {
        opacity: 0.2;
        width: 28px;
        min-width: 28px;
        height: 28px;

        img {
          width: 100%;
          height: 100%;
        }

        &Clickable {
          cursor: pointer;
          transition: opacity 0.2s linear;

          &:hover {
            opacity: 0.4;
            transition: opacity 0.2s linear;
          }
        }

        &Clear {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 20px;
          min-width: 20px;
          height: 20px;

          border-radius: 100%;
          background-color: #b5b6b6;
          color: #ffffff;
          cursor: pointer;
          opacity: 0;

          svg g g {
            fill: #fff;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  &.cursorPointer {
    .body,
    input {
      cursor: pointer;
    }

    input {
      caret-color: transparent;
    }
  }

  &:hover {
    .iconClear {
      opacity: 1 !important;
    }
  }

  &.invalid {
    .body {
      border-color: #ff3b30;
    }
  }

  &.noLabel {
    height: 50px;

    .inner {
      height: 100%;

      .header {
        display: none;
      }

      .body {
        height: 100%;
      }
    }
  }

  &.lg {
    height: 62px;

    .inner {
      border: 1px solid #e6e6e7;
      border-radius: 16px;
      padding: 9px 15px;
      background-color: #fff;

      .header {
        margin-bottom: 0;

        .label {
          color: #8e8e93;
          font-weight: 400;
          transition: all 0.2s ease;
        }

        &Big {
          .label,
          .action {
            transform: translate(0px, 10px);
            font-size: 16px;
            display: block;
            transition: all 0.2s ease;
          }

          .label {
            cursor: text;
            opacity: 0.2;
            color: #000;
            font-weight: 600;
          }
        }
      }

      .body {
        border: none;
        border-radius: 0;
        padding: 0;

        &PR {
          padding-right: 28px;
        }

        .iconAbsolute {
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      :global(.phone_number-flag) {
        // temp for input with PhoneNumber component as a child
        padding: 0 5px 0 0;
      }
    }

    &.invalid {
      .inner {
        border-color: #ff3b30;
      }

      .body {
        border-color: transparent;
      }
    }

    &.cursorPointer {
      .inner {
        cursor: pointer;
      }
    }
  }

  .rmBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.25;
    width: 28px;
    min-width: 28px;
    height: 28px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    svg {
      width: 28px;
      height: 28px;
    }

    &:hover {
      opacity: 1;
    }
  }

  &.active {
    .body {
      border-color: #17a2c6 !important;
    }

    &.lg {
      .body {
        border-color: unset !important;
      }

      .inner {
        border-color: #17a2c6 !important;
      }
    }
  }

  &.readonly {
    .label,
    input {
      cursor: default !important;
    }

    .body {
      background-color: #f6f6f6;
    }

    &.lg {
      .inner {
        background-color: #f6f6f6;
      }
    }
  }

  &.unfocused {
    input {
      opacity: 0;
    }
  }
}

.btn.btn-filter-outline {
  position: relative;
  padding: 6px 14px 8px;
  font-weight: 600;
  min-width: 110px;
  text-align: left;
  border-radius: 10px;
  border: solid 1px #e6e6e7;
  transition: color 0.3s ease-in-out;
  white-space: nowrap;

  &:before {
    position: relative;
    display: inline-block;
    content: '';
    width: 28px;
    height: 28px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 5px;
    margin-bottom: -15px;
    transform: translateY(-7px);
    background-image: url('~ziphy-web-shared/basic/assets/images/ui/filters-list.svg');
    opacity: 0.25;
  }

  &:empty {
    min-width: auto;

    &:before {
      margin-right: 0;
    }
  }

  &.btn--active {
    background-color: #f6f6f6;
    border-color: #f6f6f6;
  }
}

.pd-search-params {
  .row {
    margin-bottom: -10px;

    > [class*='col'] {
      margin-bottom: 10px;
    }
  }

  &-item {
    &--search {
      max-width: 300px;
      @media (max-width: 767.98px) {
        max-width: 100%;
      }
    }

    &--date {
      max-width: 310px;
      @media (max-width: 767.98px) {
        max-width: 100%;
      }
    }
  }

  &-filters {
    &--input {
      @media (max-width: 991.98px) {
        max-width: calc(100% - 130px);
      }
    }

    &--btn {
      align-self: flex-end;
      margin-left: auto;
      @media (max-width: 991.98px) {
        max-width: 130px;
      }
    }
  }
}

.event_details-wrapper {
  .isWindows .styled_scroll & {
    padding-right: 8px;
  }

  & + & {
    margin-top: 30px;
  }

  &-label {
    padding: 3px 15px;
    border-radius: 5px;
    border: solid 1px transparent;

    //font-size: 18px;
    //line-height: 1.56;
    margin-bottom: 10px;
    font-weight: bold;
  }
}

.event_details {
  position: relative;
  padding: 10px 15px 15px;
  border-radius: 16px;
  background-color: #f6f6f6;

  & + & {
    margin-top: 10px;
  }

  &-header {
    display: flex;
    flex-wrap: wrap;

    margin-left: -20px;
  }

  &-text,
  &-title,
  &-code {
    margin-left: 20px;
  }

  &-text,
  &-title {
    margin-right: auto;
  }

  &-title {
    font-weight: bold;
    white-space: pre-line;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &-code {
    color: #8e8e93;
  }

  &-body {
    margin-top: 10px;
  }

  &-line {
    white-space: pre-line;
    word-break: break-word;

    &--date {
      color: #8e8e93;
    }
  }

  &-range {
    margin-top: 20px;

    &-title {
      color: #8d8e93;
    }
  }
}

.ps_documents {
  &-group {
    display: flex;
    margin-left: -10px;

    &--has-scroll {
      position: relative;

      // fix margin-left when scrolled
      &:before {
        display: block;
        content: '';
        width: 10px;
        position: absolute;
        left: 0;
        z-index: 1;
        top: 0;
        bottom: 0;
        background-color: #ffffff;
      }

      &:after {
        display: block;
        content: '';
        width: 30px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: linear-gradient(to left, #fff 23%, rgba(255, 255, 255, 0));
      }
    }

    &-scroll {
      display: flex;
      overflow-x: scroll;

      width: calc(100% + 40px);
      padding-right: 20px;

      @include hide-scrollbar;

      &--wrapped {
        flex-wrap: wrap;
        margin-bottom: -10px;
      }
    }
  }
}

.ps_patient_nav {
  .row {
    margin-bottom: -20px;

    & > [class*='col-'] {
      margin-bottom: 20px;
    }

    @media (max-width: 991.98px) {
      margin-bottom: -10px;

      & > [class*='col-'] {
        margin-bottom: 10px;
      }
    }
  }

  &-item {
    display: block;
    position: relative;
    padding: 15px;
    border-radius: 16px;
    color: inherit;
    background-color: #f6f6f6;
    transition: background-color 0.3s ease-in-out;

    &:hover,
    &:focus,
    &:active {
      color: inherit;
      background-color: #f0f0f0;
    }

    &-label {
      @include ellipsis;

      span {
        color: #999999;
      }
    }

    &-icon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      min-width: 48px;
      height: 48px;
      margin-bottom: 10px;
      border-radius: 100%;

      svg {
        width: 36px;
        height: 36px;
      }
    }

    @media (max-width: 991.98px) {
      display: flex;
      align-items: center;
      padding-top: 11px;
      padding-bottom: 11px;
      padding-right: 53px;

      &:before {
        display: block;
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 28px;
        height: 28px;
        opacity: 0.25;
        transition: opacity 0.3s ease-in-out;
        background: url('~ziphy-web-shared/basic/assets/images/ui/next.svg') no-repeat center;
      }

      &:hover:before {
        opacity: 1;
      }

      &-label {
        margin-left: 10px;
        flex: 1;
        position: relative;
        padding-right: 50px;

        span {
          position: absolute;
          right: 0;
          padding-left: 10px;
        }
      }

      &-icon {
        width: 40px;
        min-width: 40px;
        height: 40px;
        margin-bottom: 0;

        svg {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}

.badge-self_reported {
  padding: 1px 5px;
  font-size: 11px;
  line-height: 1.45;
  border-radius: 10px;
  font-weight: normal;
  background-color: #e7f5f9;
  white-space: nowrap;
}

.day-picker-selector {
  height: 48px;
  margin: 30px 0 20px;
  padding: 10px;
  background-color: #f6f6f6;
  border-radius: 16px;

  & > .body {
    position: relative;
    display: flex;
    justify-content: space-between;

    .current-date {
      .month,
      .year {
        margin-right: 5px;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.56;
        color: #17a2c6;
        cursor: pointer;
        user-select: none;

        &:hover {
          opacity: 0.8;
          transition: color 0.3s ease-in-out;
        }

        &:active {
          opacity: 1;
        }
      }
    }

    .button {
      cursor: pointer;

      &:hover {
        opacity: 0.4;
        transition: opacity 0.3s ease-in-out;
      }

      &:active {
        opacity: 1;
      }

      &.disabled {
        &,
        &:hover,
        &:active {
          opacity: 0.2;
          cursor: default;
        }
      }

      &:before {
        position: absolute;
        display: block;
        content: '';
        width: 28px;
        height: 28px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.prev:before {
        left: 10px;
      }

      &.next:before {
        right: 8px;
        background: url('~ziphy-web-shared/basic/assets/images/ui/next.svg') no-repeat center;
      }
    }
  }
}

.DaySlotPicker {
  max-width: 698px;

  & > .body {
    .rowBoardDate {
      display: flex;
      column-gap: 10px;

      &Content {
        display: flex;
        column-gap: 10px;
      }

      &.isMobile {
        overflow-x: scroll;
        width: calc(100% + 30px);
        padding-right: 15px;
        padding-left: 15px;
        margin-left: -15px;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none; /* Hide scrollbar for Chrome, Safari and Opera */
        }
      }

      .cell {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        font-size: 14px;
        border-radius: 24px;
        background-color: #f6f6f6;
        cursor: pointer;
        user-select: none;
        transition: background-color 0.2s ease-in-out,
        color 0.2s ease-in-out;
        flex-direction: column;

        .day {
          font-weight: 600;
          line-height: 1;
        }

        .weekDay {
          font-weight: normal;
          line-height: 1;
          color: #8e8e93;
        }

        &Selected {
          font-weight: 600;
          color: #ffffff;
          background-color: #17a2c6;

          .weekDay {
            color: #ffffff;
          }
        }

        &Current {
          font-weight: 600;
          color: #17a2c6;

          &.cellSelected {
            color: #ffffff;

            .weekDay {
              color: #ffffff;
            }
          }

          .weekDay {
            color: #17a2c6;
          }
        }

        &Locked {
          color: #cccccc;
          cursor: default;
          opacity: 0.5;
          pointer-events: none;

          .day {
            font-weight: normal;
          }

          .weekDay {
            color: #cccccc;
          }

          &.cellSelected {
            color: #ffffff;
            background-color: #cccccc;

            .weekDay {
              color: #ffffff;
            }

            &.cellCurrent {
              color: #ffffff;

              .weekDay {
                color: #ffffff;
              }
            }
          }

          &.cellCurrent {
            color: #17a2c6;

            .weekDay {
              color: #17a2c6;
            }
          }
        }

        &Loader :global(.bubble-loader) {
          opacity: 0.3;
        }

        &.selectedDate {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          cursor: initial;

          padding: 0 20px;
          font-weight: 600;
          min-width: 198px;
          color: #ffffff;
          background-color: #17a2c6;

          .cellClose {
            cursor: pointer;

            svg g g {
              fill: #fff;
            }
          }
        }

        &.moreDates {
          width: auto;
          padding: 0 20px;
          font-weight: 600;
        }
      }

      .notice {
        display: flex;
        justify-content: center;
        margin-top: 5px;
        font-size: 13px;
        color: #8e8e93;
      }
    }
  }
}

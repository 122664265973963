.captcha {
  margin-top: 10px;
  border: 1px solid #e6e6e7;
  border-radius: 16px;
  padding: 10px 15px;

  &Success {
    text-align: center;
    font-weight: bold;
    border-color: #ffe27f;
    background-color: rgba(255, 226, 127, 0.3);
  }
}

.isLoading {
  :global {
    text-align: center;
    //min-height: 335px;
  }

  #aws-captcha-container {
    display: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.item {
  flex: 1;

  display: flex;
  gap: 10px;

  background: #f6f6f6;
  border-radius: 16px;
  padding: 10px 15px;
  min-height: 62px;

  .photo {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }

  .content {
    padding-top: 4px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;

    &Meta {
      font-size: 13px;
      line-height: 1.54;
    }
  }
}

:global(.react-loading-skeleton) {
  display: flex;
}

.wrapper {
  position: relative;

  .flag {
    left: 10px;
    width: 53px;
    height: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;

    &:after {
      display: block;
      width: 1px;
      height: 60%;
      content: '';
      background-color: #e9e9e9;
      position: absolute;
      right: 0;
      top: 20%;
    }
  }

  .input {
    padding-left: 63px;
  }
}

.wrapper {
  border-radius: 16px;
  background-color: #f6f6f6;
  transition: background-color 0.3s ease-in-out;

  &Clickable {
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.body {
  display: flex;
  align-items: flex-start;
  padding: 10px 15px;

  .content {
    position: relative;
    flex: 1;

    .title {
      display: flex;
      font-size: 13px;
      line-height: 20px;
    }

    .titleText {
      flex: 1;
    }

    .titlePrice {
      color: #8e8e93;
      font-size: 13px;
      line-height: 20px;
      margin-left: 5px;
    }

    .subtitle {
      font-weight: 600;
    }
  }

  .checkbox + .content {
    margin-left: 15px;
  }
}

.footer {
  position: relative;
  padding: 11px 15px 10px;
  border-radius: 0 0 16px 16px;
  font-weight: 700;
  color: #17a2c6;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  text-align: center;

  &:before {
    display: block;
    content: '';
    height: 1px;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    background-color: #e8e8e8;
  }

  &:hover,
  &:focus {
    background-color: #f0f0f0;
  }
}

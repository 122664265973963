.phone_number {
  display: flex;
  align-items: center;
  min-width: 100%;
  //border-bottom: 1px solid rgba(206, 206, 208, 0.5);

  .has-error & {
    border-color: #ff3b30 !important;
  }

  &-flag {
    display: flex;
    align-items: center;
    margin-right: 5px;
    padding: 10px 5px 10px 0;
    cursor: pointer;

    &-code {
      margin-left: 10px;
      line-height: 1.25;
      color: #000000;
    }
  }

  &--disabled {
    .phone_number-flag {
      cursor: default;
    }
  }

  &-clear {
    order: 1;
    margin-left: auto;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 100%;
    background: #b5b6b6 url('~ziphy-web-shared/basic/assets/images/ui/close-white.svg') no-repeat center;
    background-size: cover;
    cursor: pointer;
  }

  &--dark {
    padding: 4px 15px;
    border-radius: 16px;
    background-color: #f6f6f6;
    border: solid 1px #f6f6f6;
  }

  &--bordered {
    padding: 4px 15px;
    border-radius: 16px;
    border: solid 1px #e6e6e7;
  }

  &-popup {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 10px);
    max-width: 345px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.35);
    overflow: hidden;
    z-index: 1;

    &--on_top {
      top: initial;
      bottom: 0;
    }

    &-header {
      padding: 15px 15px 12px;
    }

    &-search {
      position: relative;

      &:before {
        display: block;
        content: '';
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        width: 22px;
        height: 22px;
        background: url('~ziphy-web-shared/basic/assets/images/ui/search.svg') no-repeat center;
        background-size: cover;
        opacity: 0.25;
      }

      @at-root .phone_number .phone_number-field + .phone_number-popup .phone_number-popup-search input {
        border-radius: 10px;
        background-color: #f2f3f3;
        padding: 7px 10px 7px 35px;
        border: none;
        outline: none;
        font-size: 15px;
        line-height: 1.47;
        color: #000000;
        width: 100%;

        &::placeholder {
          color: #d1d1d6;
        }
      }
    }

    &-body {
      min-height: 234px;
      max-height: 234px;
      overflow-y: auto;

      -webkit-overflow-scrolling: touch;
    }

    &-country {
      position: relative;
      font-size: 15px;
      line-height: 1.47;
      letter-spacing: -0.22px;
      color: #000000;
      padding: 0 15px;
      display: flex;
      cursor: pointer;

      .country_name {
        flex: 1;
        padding-right: 6px;
      }

      .country_code {
        color: #8d8e93;
        font-weight: normal;
      }

      .country_name,
      .country_code {
        padding: 12px 0;
        border-bottom: 1px solid rgba(206, 206, 208, 0.5);
      }

      &:last-child {
        .country_name,
        .country_code {
          border-bottom: none;
        }
      }

      &--current {
        font-weight: 600;

        .country_code {
          color: #000000;
          font-weight: 600;
        }
      }

      &--preferred {
        color: #17a2c6;
      }
    }
  }
}

.wrapper {
  position: relative;
}

.button {
  display: inline;
  position: relative;
  cursor: pointer;
  padding-right: 21px;

  &:after {
    display: block;
    content: '';
    position: absolute;
    right: 0;
    bottom: -1px;
    width: 20px;
    height: 20px;
    background: url('~ziphy-web-shared/basic/assets/images/ui/down.svg') no-repeat center;
    background-size: 100% 100%;
    opacity: 0.4;
  }
}

.line {
  & + & {
    //margin-top: 10px;
  }
}

// Single Line
.firstInSingleLine {
  //
}

.lastInSingleLine {
  border-left-color: transparent !important;
}

.middleInSingleLine {
  border-left-color: transparent !important;
}

.singleInSingleLine {
  //
}

// Multi lines - TOP Line
.firstInTopLine {
  //
}

.lastInTopLine {
  border-left-color: transparent !important;
}

.middleInTopLine {
  border-left-color: transparent !important;
}

.singleInTopLine {
  //
}

// Multi lines - BOTTOM Line
.firstInBottomLine {
  border-top-color: transparent !important;
}

.lastInBottomLine {
  border-left-color: transparent !important;
  border-top-color: transparent !important;
}

.middleInBottomLine {
  border-left-color: transparent !important;
  border-top-color: transparent !important;
}

.singleInBottomLine {
  border-top-color: transparent !important;
}

// Multi lines - MIDDLE Line
.firstInMiddleLine {
  border-top-color: transparent !important;
}

.lastInMiddleLine {
  border-top-color: transparent !important;
}

.middleInMiddleLine {
  border-top-color: transparent !important;
}

.singleInMiddleLine {
  border-top-color: transparent !important;
}

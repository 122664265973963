.button {
  position: relative;

  padding: 15px;
  border-radius: 16px;
  background-color: #F6F6F6;

  transition: background-color 0.3s ease-in-out;
  height: 130px;

  font-weight: 700;
  color: #8d8e93;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 28px;
    height: 28px;
    background-image: url('~ziphy-web-shared/basic/assets/images/ui/plus.svg');
    opacity: 0.2;
  }
}

.group {
  margin: 0;
}

.item {
  word-break: break-word;
}

.label {
  color: #8e8e93;
}

.muted {
  color: #8e8e93;
}

:global(.dtl-group--as-list) {
  .group {
    margin: 0;
  }

  &[class*='mb-'] {
    margin-bottom: 0;
  }

  .item:not(:empty) {
    display: flex;
    border-bottom: 1px solid #e1e1e1;
    padding: 15px 0;
    margin-bottom: 0;

    &[class*='col-'] {
      margin-bottom: 0;

      @media (max-width: 991.98px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    &:first-child {
      padding-top: 0;
    }
  }

  .label {
    margin-right: 15px;
    white-space: nowrap;
  }

  .value {
    font-weight: 600;
    margin-left: auto;
    text-align: right;
  }
}


.group {
  --connected-group-border-radius: 16px;

  flex: 1;
  align-self: stretch;

  display: flex;
  flex-wrap: nowrap;

  visibility: hidden;

  &.initialized {
    visibility: initial;
  }
}

.group.wrap {
  flex-wrap: wrap;

  & > * {
    flex: 1 0 auto;
    width: auto;
    min-width: auto;
  }
}

.group.asColumn {
  flex-direction: column;

  & > * {
    flex: 1 0 auto;
    width: auto;
    min-width: 100%;
  }
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;

  border-radius: 16px;
  background-color: #f6f6f6;
  padding: 10px;
  overflow: hidden;

  .selectAll {
    margin-right: 8px;

    &:empty {
      margin-right: 0;
    }
  }

  .btn {
    border-radius: 12px;
    padding: 9px 15px;
    margin-right: 8px;
    font-weight: 600;
    white-space: nowrap;
    min-width: auto;


    position: absolute;
    top: -9999px;
    right: -9999px;

    &Visible {
      position: relative;
      top: initial;
      right: initial;
    }

    &:last-child {
      margin-right: 0;
    }

    &.btnMore {
      margin-left: auto;
      padding-right: 40px;

      &:after {
        position: absolute;
        right: 8px;
        top: calc(50% - 14px);
        width: 28px;
        height: 28px;
        content: '';
        display: block;
        background: url('~ziphy-web-shared/basic/assets/images/ui/down.svg') no-repeat center;
        background-size: contain;
        opacity: 0.3;
      }
    }

    &:global(.btn-black).btnMore {
      &:after {
        background: url('~ziphy-web-shared/basic/assets/images/ui/down-white.svg') no-repeat center;
        opacity: 0.9;
      }
    }
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  overflow-x: hidden;
  color: #000000;
}

.a,
a {
  color: #17a2c6;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #1386a4;
    text-decoration: none;
  }
}

:focus {
  outline: none;
}

// todo: change to title mixins
.title-xs,
.title-sm,
.title-md,
.title-lg {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  padding-bottom: 0;
}

.title-xs {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 10px;
}

.title-sm {
  font-size: 18px;
  line-height: 1.56;
  margin-bottom: 10px;
}

.title-md {
  font-size: 22px;
  line-height: 1.36;
  margin-bottom: 10px;
}

.title-lg {
  font-size: 32px;
  line-height: 1.5;
  margin-bottom: 30px;

  &.text-ellipsis {
    @include ellipsis;
    flex-grow: 1;
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .title-md {
    line-height: 1.27;
  }

  .title-lg {
    font-size: 22px;
    line-height: 1.27;
  }
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.text-muted {
  color: #8e8e93;
}

.text-danger {
  color: #ff3b30;
}

.text-meta {
  font-size: 13px;
  font-weight: normal;
  line-height: 1.54;
  color: #8e8e93;
}

.text-ellipsis {
  @include ellipsis;
}

.text-clamp-1 {
  @include line-clamp(1);
}

.text-clamp-2 {
  @include line-clamp(2);
}

.text-clamp-3 {
  @include line-clamp(3);
}

.text-capitalize {
  text-transform: capitalize;
}

.text-no-select {
  user-select: none;
}

.text-pre-line {
  white-space: pre-line;
}

.text-first-letter-uppercase:first-letter {
  text-transform: uppercase;
}

.text-nowrap {
  white-space: nowrap;
}

.badge-value.text-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.search-popup-search_by-value.text-clamp,
.search-popup-group-item.text-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.value-text-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.position_relative {
  position: relative;
}

.h-100 {
  height: 100%;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-30i {
  margin-bottom: 30px !important;
}

.mb--2 {
  margin-bottom: -2px !important;
}

.mb--5 {
  margin-bottom: -5px !important;
}

.mb--10 {
  margin-bottom: -10px !important;
}

.mb--20 {
  margin-bottom: -20px;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-30i {
  margin-top: 30px !important;
}

.mt-100 {
  margin-top: 100px;
}

.mt--10 {
  margin-top: -10px;
}

.mt--20 {
  margin-top: -20px;
}

.mt_mt-10 + .mt_mt-10 {
  margin-top: 10px;
}

.mt_mt-20 + .mt_mt-20 {
  margin-top: 20px;
}

.mt_mt-30 + .mt_mt-30 {
  margin-top: 30px;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.pe-none {
  pointer-events: none;
}

.container-overflow-x-hidden {
  overflow-x: hidden;
}

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  max-width: 100%;

  @media (min-width: 992px) {
    max-width: 1200px;
  }
}

.max_width--md {
  max-width: 760px;
}

.max_width--lg {
  max-width: 860px;
}

.row {
  &--gutters-0 {
    margin-left: 0;
    margin-right: 0;

    & > [class*='col'] {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--gutters-10 {
    margin-left: -10px;
    margin-right: -10px;

    & > [class*='col'] {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &--gutters-5 {
    margin-left: -5px;
    margin-right: -5px;

    & > [class*='col'] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  &--equal_height {
    display: flex;
    flex-wrap: wrap;

    & > [class*='col-'] {
      display: flex;

      & > div,
      & > [class*='-item'] {
        width: 100%;
      }
    }
  }

  @media (max-width: 991.98px) {
    margin-left: -10px;
    margin-right: -10px;

    & > [class*='col-'] {
      padding-left: 10px;
      padding-right: 10px;
    }

    &--gutters-5 {
      margin-left: -5px;
      margin-right: -5px;

      & > [class*='col'] {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  &--mb-10 {
    margin-bottom: -10px;

    & > [class*='col-'] {
      margin-bottom: 10px;
    }
  }

  &--mb-20 {
    margin-bottom: -20px;

    & > [class*='col-'] {
      margin-bottom: 20px;
    }
  }

  &--mb-30 {
    margin-bottom: -30px;

    & > [class*='col-'] {
      margin-bottom: 30px;
    }
  }
}

.isWindows {
  .styled_scroll {
    overflow-y: scroll;
    overflow-x: hidden;

    & > * {
      margin-right: -8px;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #f00;

      &-thumb {
        border-radius: 10px;
        border-width: 3px 3px 3px 1px;
        border-style: solid;
      }

      &-button {
        display: none;
      }
    }

    &:not(.styled_scroll--locked):hover::-webkit-scrollbar {
      &-thumb {
        background-color: #babac0;

        &:hover {
          background-color: #a0a0a5;
        }
      }
    }

    @mixin add-bgc() {
      &::-webkit-scrollbar {
        background-color: $scrollBgc;
        border-color: $scrollBgc;

        &-track,
        &-track:hover {
          background-color: $scrollBgc;
        }

        &-thumb {
          background-color: $scrollBgc;
          border-color: $scrollBgc;
        }
      }
    }

    $scrollBgc: #ffffff;
    @include add-bgc();

    &--bg-gray {
      $scrollBgc: #f9f9f9;
      @include add-bgc();
    }
  }
}

// layout
html,
body {
  height: 100%;
}

body {
  -webkit-overflow-scrolling: touch;
}

.isWindows body {
  overflow-y: scroll;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
}

// common
// todo: remove ct_actions
.ct_actions {
  position: absolute;
  right: 15px;
  top: 17px;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    position: absolute;
    display: block;
    content: '';
    left: -11px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 28px;
    background-color: #e0e0dd;
  }

  &-btn {
    opacity: 0.25;
    transition: opacity 0.3s ease-in-out;
    width: 28px;
    height: 28px;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &--info {
      background: url('~ziphy-web-shared/basic/assets/images/ui/info.svg') no-repeat center;
    }

    &--edit {
      background: url('~ziphy-web-shared/basic/assets/images/ui/settings.svg') no-repeat center;
    }

    &--remove {
      background: url('~ziphy-web-shared/basic/assets/images/ui/trash.svg') no-repeat center;
    }
  }

  &--dark &-btn {
    opacity: 1;
  }

  &--x1 {
    padding-right: 68px;
  }

  &--full {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding-left: 11px;
    padding-right: 15px;

    cursor: pointer;

    &:hover .ct_actions-btn {
      opacity: 1;
    }

    &:before {
      left: 0;
    }
  }

  &--max-63 {
    bottom: initial;
    height: 100%;
    max-height: 63px;
  }

  &--inline {
    position: relative;
    right: initial;
    left: initial;
    top: initial;

    &:before {
      display: none;
    }
  }

  &--outside {
    left: calc(100% + 10px);
    right: initial;

    &:before {
      display: none;
    }
  }
}

.meta {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;

  &--secondary {
    font-weight: 600;
    line-height: 1.25;
    color: #17a2c6;
    background-color: #f6f6f6;
  }

  &--secondary-dark {
    font-weight: 600;
    line-height: 1.25;
    background-color: #f6f6f6;
  }
}

.meta-group {
  position: relative;
  margin-left: -5px;
  margin-bottom: -10px;

  .meta {
    margin-left: 5px;
    margin-bottom: 10px;
  }
}

.badge-group {
  position: relative;
  margin-left: -10px;
  margin-bottom: -10px;

  .badge {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

.box {
  position: relative;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  &-header {
    position: relative;
    padding-bottom: 10px;

    &-link {
      text-align: right;
      font-weight: bold;
      line-height: 28px;
      color: #17a2c6;
      cursor: pointer;

      &:hover,
      &:focus {
        color: #1386a4;
        text-decoration: none;
      }
    }
  }

  &-body {
    position: relative;

    &--padding {
      padding: 20px 0;
    }
  }

  &-footer {
    padding-top: 10px;
  }

  &--full-height {
    display: flex;
    flex-direction: column;
    height: 100%;

    .box-body {
      @media (max-width: 991.98px) {
        overflow-y: auto;
        flex: 1;
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .box-footer {
      @media (max-width: 991.98px) {
        margin-top: auto;

        :not(.btn-group) .btn {
          display: block;
        }
      }
    }
  }

  &--with-icon {
    .box-icon {
      position: absolute;
      top: -37px;
      left: -48px;

      @media (max-width: 991.98px) {
        top: -51px;
        left: initial;
        right: -41px;
      }
    }

    .box-header {
      padding-left: 130px;

      @media (max-width: 991.98px) {
        padding-left: 0;
      }
    }
  }

  &--md {
    max-width: 760px;
  }

  &--lg {
    max-width: 820px;
  }

  &--desktop-footer-fixed {
    @media (min-width: 992px) {
      padding-bottom: 110px;

      .box-footer {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 30px 15px;
        box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.15);
        background-color: #fff;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: -15px;

          height: 30px;
          width: 60px;

          @media (max-width: 1310px) {
            width: 30px;
          }
        }

        &:before {
          left: 0;
          background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        }

        &:after {
          right: 0;
          background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        }

        .btn {
          padding: 15px 20px;
          margin-top: 0;
        }
      }
    }
  }

  &--mobile-footer-fixed {
    @media (max-width: 991.98px) {
      padding-bottom: 80px;
    }

    .box-footer {
      @media (max-width: 991.98px) {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 15px;
        box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.15);
        width: 100vw;
        background-color: #fff;
      }

      .btn {
        @media (max-width: 991.98px) {
          padding: 15px 20px;
          margin-top: 0;
          width: 100%;
        }
      }
    }
  }
}

.text-group {
  p {
    margin-bottom: 0;

    &.mb-30 {
      margin-bottom: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--md {
    max-width: 760px;
  }

  &--lg {
    max-width: 820px;
  }
}

.user_photo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  overflow: hidden;

  text-transform: uppercase;

  background-color: #f6f6f6;

  width: 40px;
  min-width: 40px;
  height: 40px;

  &--add,
  &--empty {
    color: #ffffff;
    background-color: #17a2c6;

    font-size: 18px;
    font-weight: 600;
    line-height: 1.22;
    text-align: center;
  }

  &--add {
    cursor: pointer;

    &:after {
      display: block;
      content: '';
      width: 28px;
      height: 28px;
      background: url('~ziphy-web-shared/basic/assets/images/ui/plus-white.svg') center no-repeat;
      background-size: cover;
    }
  }

  &--100 {
    width: 100px;
    min-width: 100px;
    height: 100px;

    font-size: 42px;
  }

  &--80 {
    width: 80px;
    min-width: 80px;
    height: 80px;

    font-size: 36px;
  }

  &--48 {
    width: 48px;
    min-width: 48px;
    height: 48px;

    font-size: 22px;
  }

  &--gray {
    filter: grayscale(1);

    &.user_photo--empty {
      background-color: #e0e0e0;
      filter: none;
    }
  }

  img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.upload_photo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }

  .user_photo {
    width: 100px;
    min-width: 100px;
    height: 100px;
    font-size: 34px;
    cursor: pointer;

    &--add:after {
      width: 56px;
      min-width: 56px;
      height: 56px;
    }
  }

  &-btn {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    text-align: center;
    color: #17a2c6;
    margin-top: 10px;
    cursor: pointer;
  }

  input[type='file'] {
    display: none;
  }
}

.search {
  position: relative;

  &-label {
    display: block;
    font-size: 13px;
    line-height: 1.54;
    font-weight: 600;
    padding-bottom: 2px;
    margin-bottom: 0;

    .search--lg & {
      padding: 9px 15px 0;
      color: #8e8e93;
      font-weight: 400;
    }
  }

  &-item {
    display: flex;
    position: relative;
    padding: 11px 15px 11px 48px;
    border-radius: 16px;
    background-color: #f2f3f3;
    overflow: hidden;

    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 28px;
      height: 28px;
      background: url('~ziphy-web-shared/basic/assets/images/ui/search.svg') no-repeat center;
      opacity: 0.25;
    }

    .search--light & {
      background-color: #f6f6f6;
    }

    .search--asField & {
      background-color: transparent;
      border: 1px solid #e6e6e7;
    }

    .search--sm & {
      padding-top: 6px;
      padding-bottom: 6px;
      border-radius: 10px;
    }

    .search--lg & {
      background-color: #fff;
      padding: 0 15px 9px;
      overflow: visible;

      &:before {
        display: none;
      }
    }

    .search--readonly & {
      background-color: #f6f6f6;
    }
  }

  &-field {
    flex: 1;
    display: block;
    width: 100%;
    padding: 4px 0;
    border: none;
    outline: none;

    line-height: 1.25;
    font-weight: normal;
    color: #000000;
    background: transparent;

    .search--sm & {
      font-size: 14px;
      line-height: 1.43;
    }

    .search--asField & {
      padding: 1px 0;
      font-size: inherit;
      line-height: inherit;
    }

    &::placeholder {
      color: #d1d1d6;
    }
  }

  &-clear {
    order: 1;
    display: none;
    margin: 2px 0 0 5px;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: #b5b6b6 url('~ziphy-web-shared/basic/assets/images/ui/close-white.svg') no-repeat center;
    background-size: cover;
    cursor: pointer;

    .search--sm & {
      width: 20px;
      height: 20px;
      margin-top: 4px;
    }

    .search--lg & {
      position: absolute;
      bottom: 30px;
      right: 15px;
      transform: translateY(50%);
      z-index: 5;
    }

    .search--readonly & {
      display: none;
    }
  }

  &-help {
    margin-top: 5px;
    font-size: 13px;
    line-height: 1.54;
    color: #8e8e93;
  }

  &-no_result {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  &--active {
    .search-clear {
      display: block;
    }
  }

  &--lg {
    display: flex;
    flex-direction: column;
    border: 1px solid #e6e6e7;
    border-radius: 16px;
    height: 60px;

    input {
      padding: 0;
    }

    &.has-error {
      border-color: #ff3b30;
    }
  }

  &--readonly {
    background-color: #f6f6f6;
  }
}

.search-popup {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 10px);
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.35);
  overflow: hidden;
  z-index: 100;

  &-scroll {
    padding: 10px 0;
    max-height: 70vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &-item {
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: #f0f0f0;
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
    }

    &-meta {
      font-size: 13px;
      line-height: 1.54;
      color: #8e8e93;
    }
  }

  &-group {
    &-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      padding: 5px 15px;
    }

    &-item {
      position: relative;
      padding: 5px 15px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 11px;

      .check-container {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #f2f3f3;
        display: flex;
        align-items: center;
        justify-content: center;

        &__icon {
          opacity: 0;

          g {
            fill: #ffffff;
          }
        }
      }

      &--active {
        .check-container {
          background-color: #17a2c6;

          &__icon {
            opacity: 1;
          }
        }
      }

      em {
        font-style: normal;
        font-weight: 600;
      }
    }

    &-loader {
      position: relative;
      padding: 0 15px 5px;

      .bubble-loader {
        left: 15px;
        opacity: 0.2;
      }
    }
  }

  &-search_by {
    font-size: 14px;
    margin-top: -10px;
    padding-bottom: 10px;

    &--bordered {
      border-top: 1px solid #e6e6e7;
      margin-top: 0;
      padding-top: 10px;
    }

    &-btn {
      display: flex;
      text-transform: none;
      margin: 0;
      width: 100%;
      text-align: left;
      background: none;
      border: none;
      outline: none;

      padding: 10px 15px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: #f0f0f0;
      }

      .search-popup-search_by--group & {
        padding: 5px 15px;
      }
    }

    &-label {
      display: inline-block;
    }

    &-value {
      display: inline;
      margin-left: 5px;
      font-weight: 600;
    }
  }

  &-error {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
  }
}

.simple_header {
  display: flex;
  align-items: flex-end;
  position: relative;

  &-link {
    text-align: right;
    font-weight: 600;
    line-height: 28px;
    margin-left: auto;
    padding-left: 10px;
    color: #17a2c6;
    white-space: nowrap;
    cursor: pointer;

    &:hover,
    &:focus {
      color: #1386a4;
      text-decoration: none;
    }

    & + & {
      margin-left: 10px;
    }
  }
}

.fake-border {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  border: solid 2px #ff3b30;
  border-radius: 16px;
}

.red_marker {
  position: relative;

  &:not(.btn) {
    font-size: 0;
  }

  &:after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 2px;
    border-radius: 100%;
    background-color: #ff3b30;
    vertical-align: super;
  }

  &--info {
    vertical-align: middle;

    &:after {
      text-align: center;
      content: '!';
      width: 16px;
      height: 16px;
      font-size: 16px;
      line-height: 15px;
      color: #ffffff;
    }
  }
}

.patient_item {
  position: relative;
  display: flex;
  padding: 15px;
  margin: 0;
  border-radius: 16px;

  background-color: #f6f6f6;
  transition: background-color 0.3s ease-in-out;

  .user_photo {
    margin-right: 10px;
  }

  &-content {
    flex: 1;
    max-width: calc(100% - 50px);

    &-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      color: #000000;
      @include ellipsis;
    }

    &-meta {
      font-size: 13px;
      line-height: 1.54;
      color: #8e8e93;
      @include ellipsis;
    }
  }

  &--link {
    cursor: pointer;

    &:hover {
      background-color: #f2f3f3;
    }
  }
}

.alert {
  padding: 15px;
  border-radius: 16px;
  border: solid 1px #e0e0dd;

  &-title {
    font-weight: 700;
  }

  &--warning {
    border-color: #ffe27f;
    background-color: rgba(255, 226, 127, 0.3);
  }

  &--danger {
    color: #ffffff;
    border-color: #ff3b30;
    background-color: #ff3b30;
  }
}

.action-point {
  &--pay_attention:before,
  &--completed:before {
    display: inline-block;
    content: '';
    width: 16px;
    min-width: 16px;
    height: 16px;
    border-radius: 100%;
    position: relative;
    margin-right: 5px;
  }

  &--pay_attention:before {
    background-color: #ff3824;
  }

  &--completed:before {
    background-color: #07dd89;
  }
}

.wrapper {
  position: relative;
  border-radius: 16px;

  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  display: flex;
  align-items: center;
  pointer-events: none;

  padding: 0 15px;
  min-height: 62px;
  min-width: 1%;

  color: inherit;

  &:hover,
  &:focus {
    color: inherit;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    min-width: 1px;

    padding-top: 9px;
    padding-bottom: 9px;
  }

  &:not(.clearValue) .value {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;

    display: inline-flex;

    &Primary {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &Secondary {
      margin-left: 5px;
    }
  }

  &:not(.clearLabel) .label {
    font-size: 13px;
    line-height: 1.54;
    color: #8e8e93;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    margin-top: 2px;
  }
}

// Modes
.wrapper.lg {
  padding: 0 15px;
  min-height: 70px;

  .content {
    padding-top: 13px;
    padding-bottom: 13px;
  }
}

.wrapper.clickable {
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  pointer-events: initial;

  &:hover {
    background-color: #f0f0f0;
  }
}

.wrapper.transparent {
  background-color: transparent;
  border-color: transparent;

  &:hover {
    background-color: transparent;
  }
}

.wrapper.bordered {
  border-color: #e6e6e7;
}

.wrapper.bordered.withError,
.wrapper.withError {
  border-color: #ff3b30;
}

.wrapper.reverseContent {
  .content {
    flex-direction: column-reverse;
  }

  &:not(.clearValue) .value {
    margin-top: 2px;
  }

  &:not(.clearLabel) .label {
    margin-top: initial;
  }
}

.wrapper.noPadding {
  padding: 0;

  .content {
    padding-top: 0;
    padding-bottom: 0;
  }
}

// Left Element
.leftElement {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
  pointer-events: initial;
}

// Right Element
.rightElement {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  padding-left: 11px;
  pointer-events: initial;

  transition: opacity 0.3s ease-in-out;

  &:before {
    position: absolute;
    display: block;
    content: '';
    left: -1px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 24px;
    background-color: #e0e0dd;
  }

  .icon {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    opacity: 0.2;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
}

// Right Second Element
.rightSecondElement {
  position: absolute;
  align-self: stretch;
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: inherit;
  padding: 0 9px 0 10px;
  border-radius: 0 16px 16px 0;
  cursor: default;

  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }

  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 10px;
    height: 100%;
    left: -10px;
    align-self: stretch;
    background: linear-gradient(to right, rgba(246, 246, 246, 0), rgba(246, 246, 246, 1));
    transition: background 0.3s ease-in-out;
  }

  .icon {
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    opacity: 0.2;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 1;
    }

    & + & {
      margin-left: 10px;
    }
  }
}

.wrapper:hover .rightSecondElement:before {
  background: linear-gradient(to right, rgba(240, 240, 240, 0), rgba(240, 240, 240, 1));
}

.wrapper.hiddenRightElement {
  .rightElement {
    opacity: 0;
  }

  &:hover .rightElement {
    opacity: 1;
  }
}

.wrapper.clearRightElement {
  .rightElement {
    &:before {
      display: none;
    }
  }
}

.wrapper.dottedSecondaryValue {
  .valueSecondary {
    color: #8d8e93;
    white-space: nowrap;

    &:before {
      content: ' • ';
      display: inline;
    }
  }
}

// Arrow
.arrow {
  margin-left: 10px;
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out;
}

.wrapper.clickable:hover .arrow {
  opacity: 1;
}

// Loader
.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  background-color: inherit;
}

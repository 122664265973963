.badge {
  margin-left: 5px;
  line-height: inherit;
  padding: 0 10px;
}

.list {
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: 991.98px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

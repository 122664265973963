.details {
  position: relative;

  :global {
    .btn-group .btn-primary {
      @media (max-width: 991.98px) {
        display: block;
        min-width: calc(100% - 10px);
        margin-top: 10px;
      }
    }

    .info-box_label {
      margin-bottom: 0;
      margin-top: 2px;
    }

    .tsform-item--readonly .tsform-field input {
      cursor: pointer;
    }
  }
}

.default_layout {
  position: relative;
  height: 100%;
  background-color: #ffffff;

  .sidebar {
    transition: left 0.4s ease-in-out,
    bottom 0.3s ease-in-out;

    @media (max-width: 991.98px) {
      left: -260px;

      .sidebar-open & {
        left: 0;
      }
    }
  }
}

.content-wrapper {
  position: relative;

  display: flex;
  flex-direction: column;

  left: 260px;
  max-width: calc(100% - 260px);
  transition: left 0.4s ease-in-out;

  @media (max-width: 991.98px) {
    left: 0;
    min-width: 100%;
    max-width: 100%;

    .sidebar-open & {
      min-width: 100vw;
      left: 260px;
      max-width: calc(100% - 260px);
    }
  }

  &--full-height {
    height: 100%;
  }

  &--full-width {
    left: 0;
    min-width: 100%;

    @media (max-width: 991.98px) {
      .sidebar-open & {
        min-width: 100%;
        left: 0;
      }
    }
  }

  &-overlay {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.52);

    @media (max-width: 991.98px) {
      display: block;
    }
  }
}

.content-app-wrapper {
  position: relative;
  height: 100%;
  //min-height: 100%;
  //flex: 1;
  background: #ffffff;
  transition: margin-bottom 0.3s ease-in-out;
}

.content {
  position: relative;
  min-height: 100%; /* For Mozilla */
  min-height: -webkit-fill-available;
  min-height: -moz-available;
  min-height: stretch;
  padding: 0 0 30px;

  @media (max-width: 991.98px) {
    padding-bottom: 15px;
  }
}

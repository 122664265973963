.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border-radius: 10px;
  padding: 0 10px 0 4px;
  height: 28px;

  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  color: rgba(142, 142, 147, 1);

  @media (min-width: 992px) {
    //width: 36px;
    height: 36px;
    background-color: #f6f6f6;
    transition: background-color 0.3s ease-in-out;

    svg {
      transition: color 0.3s ease-in-out;
    }

    &:hover {
      background-color: #f0f0f0;

      svg {
        color: #000000;
      }
    }
  }

  &RedMark {
    &:before {
      content: '';
      position: absolute;
      top: -4px;
      right: -4px;
      width: 14px;
      height: 14px;

      border-radius: 50%;
      background-color: red;
      border: 2px solid #ffffff;
    }
  }
}

@import '@assets/styles/mixins';

.header {
  display: flex;

  @media (max-width: 991.98px) {
    flex-direction: column;
    align-items: center;
  }
}

.content {
  display: inline-flex;
  align-items: center;

  margin-right: auto;
  padding-right: 20px;

  max-width: 100%;
  min-width: 1px;

  :global(.user_photo) {
    margin-right: 10px;
  }

  .text {
    @include ellipsis;

    margin-bottom: 0;
  }
}

.buttonGroup {
  display: flex;
  align-items: center;
  white-space: nowrap;

  @media (max-width: 991.98px) {
    margin-top: 10px;
  }

  .button {
    @include a;
    @include title-sm;

    display: inline-block;
    font-weight: 600;
  }

  .button + .button {
    margin-left: 10px;
  }
}

.wrapper {

}

.item {
  & + & {
    margin-top: 10px;
  }
}

.checkbox {
  margin-right: 5px;
}

.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;

  border: none;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  transition: color 0.3s ease-in-out,
  background-color 0.3s ease-in-out,
  border-color 0.3s ease-in-out;

  outline: none;

  &:focus {
    outline: none;
  }

  .content {
    //
  }

  .left + .content {
    margin-left: 5px;
  }

  .content + .right {
    margin-left: 5px;
  }

  .left .icon,
  .right .icon {
    max-height: 100%;
  }

  &.isDisabled,
  &[disabled] {
    pointer-events: none;
    cursor: default;
  }


  &.isActive {
    //
  }

  &.isLoading {
    pointer-events: none;

    .left,
    .content,
    .right {
      opacity: 0;
    }

    .loader {
      position: absolute;
      display: flex;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      border-radius: inherit;
      background-color: inherit;

      .bubbleLoader {
        margin-top: -4px;
      }
    }
  }
}

/*
* Size
 */

.xs {
  font-size: 16px;
  line-height: 1.2;
  min-height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 8px;

  .icon {
    max-width: 20px;
    max-height: 20px;
  }
}

.sm {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
  min-height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 12px;
}

.md {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
  min-height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 16px;
}

.lg {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
  min-height: 60px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 16px;
}

.minAuto {
  min-width: auto;
}

.min110 {
  min-width: 110px;
}

.block {
  flex-grow: 1;
  min-width: 100%;
}

/*
* Border Radius
 */
.r0 {
  border-radius: 0 !important;
}

.r8 {
  border-radius: 8px !important;
}

.r12 {
  border-radius: 12px !important;
}

.r16 {
  border-radius: 16px !important;
}

.r20 {
  border-radius: 20px !important;
}

/*
 * Modes
 */
.btn.justIcon {
  padding-left: 0;
  padding-right: 0;

  .content {
    display: none;
  }

  &.xs {
    min-width: 30px;
  }

  &.sm {
    min-width: 40px;
  }

  &.md {
    min-width: 50px;
  }

  &.lg {
    min-width: 62px;
  }
}

.btn.primary {
  color: #ffffff;
  background-color: #17a2c6;

  &:hover, &:focus {
    color: #ffffff;
    background-color: #1386a4;
  }

  &.isDisabled, &[disabled] {
    color: #d1d1d6;
    background-color: #f2f3f3;

    &:hover, &:focus {
      background-color: #f2f3f3;
    }
  }
}

.btn.primary-outline {
  color: #17a2c6;
  border: 1px solid #e5e5e5;
  background-color: #fbfbfb;

  &:hover, &:focus:hover {
    color: #17a2c6;
    background-color: #f6f6f6;
  }

  &:focus {
    color: #17a2c6;
    background-color: #fbfbfb;
  }

  &.isDisabled, &[disabled] {
    color: #d1d1d6;

    &:hover, &:focus {
      color: #d1d1d6;
      background-color: #f6f6f6;
    }
  }
}

.btn.secondary {
  color: #17a2c6;
  background-color: #f2f3f3;

  &:hover, &:focus {
    color: #17a2c6;
    background-color: #f0f0f0;
  }

  &.isDisabled, &[disabled] {
    color: #d1d1d6;
    background-color: #f2f3f3;

    &:hover, &:focus {
      color: #d1d1d6;
      background-color: #f2f3f3;
    }
  }
}

.btn.secondary-dark {
  color: #000000;
  background-color: #f6f6f6;

  &:hover, &:focus {
    color: #000000;
    background-color: #f0f0f0;
  }

  &.isDisabled, &[disabled] {
    opacity: 0.7;

    &:hover, &:focus {
      background-color: #f6f6f6;
    }
  }
}

.btn.secondary-gray {
  color: #ffffff;
  background-color: #6d6d6d;

  &:hover, &:focus {
    color: #ffffff;
    background-color: #656565;
  }

  &.isDisabled, &[disabled] {
    opacity: 0.7;

    &:hover, &:focus {
      background-color: #6d6d6d;
    }
  }
}

.btn.secondary-danger {
  color: #ff3b30;
  background-color: #f2f3f3;

  &:hover, &:focus {
    color: #ff3b30;
    background-color: #f0f0f0;
  }

  &.isDisabled, &[disabled] {
    color: #d1d1d6;

    &:hover, &:focus {
      color: #d1d1d6;
      background-color: #f2f3f3;
    }
  }
}

.btn.secondary-icon {
  color: #d1d1d6;
  background-color: #f2f3f3;

  &:hover, &:focus {
    color: #17a2c6;
    background-color: #f0f0f0;
  }

  &.isDisabled, &[disabled] {
    color: #d1d1d6;
    background-color: #f2f3f3;

    &:hover, &:focus {
      color: #d1d1d6;
      background-color: #f2f3f3;
    }
  }
}

.btn.danger {
  color: #ffffff;
  background-color: #ff3b30;

  &:hover, &:focus {
    color: #ffffff;
    background-color: #e33329;
  }

  &.isDisabled, &[disabled] {
    background-color: #f2f3f3;

    &:hover, &:focus {
      background-color: #f2f3f3;
    }
  }
}

.btn.success {
  color: #2A5E45;
  background-color: #DEF5E6;

  &:hover, &:focus {
    color: #2A5E45;
    background-color: #d8efe0;
  }

  &.isDisabled, &[disabled] {
    background-color: #f2f3f3;

    &:hover, &:focus {
      background-color: #f2f3f3;
    }
  }
}

.btn.white {
  color: #17a2c6;
  background-color: #ffffff;

  &:hover, &:focus {
    color: #1386a4;
    background-color: #fdfdfd;
  }

  &.isDisabled, &[disabled] {
    opacity: 0.7;

    &:hover, &:focus {
      background-color: #ffffff;
    }
  }
}

.btn.white-dark {
  color: #000000;
  background-color: #ffffff;

  &:hover, &:focus {
    color: #000000;
    background-color: #fdfdfd;
  }

  &.isDisabled, &[disabled] {
    color: #d1d1d6;

    &:hover, &:focus {
      background-color: #ffffff;
    }
  }
}

.btn.black {
  color: #fff;
  background-color: #000;

  &:hover, &:focus {
    background-color: rgba(0, 0, 0, 0.7);
  }

  &.isDisabled, &[disabled] {
    opacity: 0.7;

    &:hover, &:focus {
      background-color: #000;
    }
  }
}

.btn.ghost {
  color: #000000;
  background-color: transparent;
  border: 1px solid #fff;

  &:hover, &:focus {
    color: #000000;
    background-color: #fdfdfd;
  }

  &.isDisabled, &[disabled] {
    opacity: 0.7;
    background-color: #fdfdfd;

    &:hover, &:focus {
      background-color: #ffffff;
    }
  }
}

.btn.blue {
  color: #17a2c6;
  background-color: #e7f5f9;

  &:hover, &:focus {
    color: #17a2c6;
    background-color: #d1eef4;
  }

  &.isDisabled, &[disabled] {
    color: #9cd7e6;

    &:hover, &:focus {
      background-color: #f6fafb;
    }
  }
}

.btn.anchor {
  color: #17a2c6;

  padding: 0;
  min-height: initial;
  line-height: inherit;
  border-radius: initial;

  &:hover, &:focus {
    color: #1386a4;
    text-decoration: none;
  }
}

.btn.anchor-dashed {
  color: #17a2c6;

  padding: 0;
  min-height: initial;
  line-height: inherit;
  border-radius: initial;

  border-bottom: 1px dashed #17a2c6;

  &:hover, &:focus {
    color: #1386a4;
    border-bottom-color: #1386a4;
  }
}

.btn.anchor-gray {
  color: #8d8e93;

  padding: 0;
  min-height: initial;
  line-height: inherit;
  border-radius: initial;

  &:hover, &:focus {
    color: #8e8e93;
    text-decoration: none;
  }
}

.btn.anchor-gray-dashed {
  color: #8d8e93;

  padding: 0;
  min-height: initial;
  line-height: inherit;
  border-radius: initial;

  border-bottom: 1px dashed #8d8e93;

  &:hover, &:focus {
    color: #8e8e93;
    border-bottom-color: #8d8e93;
  }
}

.btn.anchor-gray-underline {
  color: #8d8e93;

  padding: 0;
  min-height: initial;
  line-height: inherit;
  border-radius: initial;

  text-decoration: underline;

  &:hover, &:focus {
    color: #17a2c6;
    text-decoration: underline;
  }
}

.btn.custom-filters {
  color: #000000;
  border: 1px solid #e6e6e7;
  font-weight: 600;

  .left {
    opacity: 0.2;
  }

  &:hover, &:focus {
    color: #000000;
  }

  &.isDisabled, &[disabled] {
    opacity: 0.7;

    &:hover, &:focus {
      color: #000000;
    }
  }

  &.isActive {
    background-color: #f6f6f6;
    border-color: #f6f6f6;
  }
}

.wrapper {
  height: 100%;
  max-height: 365px;
  width: 100%;
  overflow-y: scroll;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 17, 77, 0.172722);
  border-radius: 16px;
  padding: 0;
  z-index: 5;

  .loader {
    margin: 19px 15px;
    position: relative;

    .bubbleLoader {
      left: 15px;
      opacity: 0.2;
    }
  }

  .scrollArea {
    overflow-y: hidden;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .error {
    margin: 19px 15px;
  }
}

.isLoading {
  overflow: hidden;

  .scrollArea {
    position: relative;
    max-height: 365px;

    &:after {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }

  .loader {
    position: absolute;
    //top: 0;
    //left: 0;
    margin: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    .bubbleLoader {
      opacity: 1;
    }
  }
}

.group {
  &:not(:first-child) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &Title {
    //font-size: 13px;
    font-weight: 600;
    //line-height: 1.54;
    //color: #8e8e93;
    padding: 10px 15px;
    //border-bottom: 1px solid #CECED080;

    em {
      font-style: normal;
      font-weight: 600;
    }
  }
}

.item {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color linear 0.2s;

  &:hover {
    background-color: #f6f6f6;
    transition: background-color linear 0.2s;
  }

  &Inner {
    display: flex;
    justify-content: space-between;

    &Title {
      width: initial;
      padding-right: 10px;
      flex-grow: 1;

      em {
        font-style: normal;
        font-weight: 600;
      }
    }

    &RightText {
      color: #8e8e93;
      text-align: right;
      white-space: nowrap;
    }

    &MetaText {
      position: relative;
      color: #8e8e93;
      white-space: nowrap;

      &:before {
        display: inline-block;
        content: ' • ';
        width: 16px;
        text-align: center;
      }
    }
  }

  &SubText {
    flex: 1;
    width: 100%;
    color: #8e8e93;
    text-align: right;
    white-space: nowrap;
  }
}

.useAsIs {
  border-top: 1px solid #ceced080;

  span {
    font-weight: 600;
  }
}

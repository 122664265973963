.wrapper {
  display: flex;
  align-self: center;

  > * + * {
    position: relative;
    margin-left: 21px !important;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -11px;
      width: 1px;
      height: 100%;
      background-color: #d2d2d2;
    }
  }
}

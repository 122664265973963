.modal-body > .new-date-picker {
  width: auto;
  padding: 0;
  border-radius: initial;
  box-shadow: none;

  @media (max-width: 991.98px) {
    & > .control {
      margin: -15px -15px 0 -15px;
      height: 44px;

      .action {
        opacity: 1;

        &.back,
        &.close {
          margin-top: 0;
          margin-left: 7px;
          margin-right: 0;
        }
      }

      .action.back ~ .action.close {
        display: none;
      }
    }

    & > .header {
      .title {
        text-align: left;
      }
    }

    & > .body {
      .day-picker .day-picker-year,
      .day-list-picker .body {
        height: auto;
        margin-left: initial;
        margin-right: initial;
        padding-left: initial;
        padding-right: initial;

        &.styled_scroll {
          overflow-y: hidden;

          & > * {
            margin-right: initial;
          }
        }
      }

      .day-list-picker .header {
        margin-left: initial;
        margin-right: initial;
        padding-left: initial;
        padding-right: initial;
      }
    }
  }
}

.progressRing {
  display: inline-block;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  fill: transparent;
}

.spin {
  animation-name: ringSpinner;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.primary {
  .base {
    stroke: #e1e1e1;
  }

  .color {
    stroke: #1386a4;
  }
}

.secondary {
  .base {
    stroke: #fff;
  }

  .color {
    stroke: #777777;
  }
}

@keyframes ringSpinner {
  from {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(270deg);
  }
}

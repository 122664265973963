.wrapper {
  :global(.modal-body) {
    position: initial;
    min-height: 400px;
    padding: 0;

    &:last-child {
      //padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

.headerPanel {
  display: flex;

  .links {
    & * + * {
      margin-left: 5px;
    }
  }

  .count {
    margin-left: auto;
    color: #8e8e93;
  }
}

.content {
  position: relative;
  width: 100%;
}

.modalBody {
  display: flex;
  align-items: stretch;
}

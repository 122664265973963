.modalBody {
  padding-top: 0 !important;
}

.infoText {
  font-size: 14px;
  color: #8e8e93;

  p:last-child {
    margin-bottom: 0;
  }
}


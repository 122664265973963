.wrapper {
  width: 100%;
  position: relative;

  @media (min-width: 991.99px) {
    background-color: #f6f6f6;
    border-radius: 16px;
    padding: 0 15px 15px;
  }

  .apptPatient {
    width: 100%;
    background-color: #f6f6f6;
    border-radius: 16px;
    padding: 15px 0;

    @media (max-width: 991.98px) {
      background-color: transparent;
      position: relative;
      padding: 0 15px 15px;

      &:after {
        display: block;
        content: '';
        width: 100%;
        height: calc(100% - 36px);
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #f6f6f6;
        border-radius: 16px;
        z-index: 1;
      }
    }
  }

  .name {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 33px;
    z-index: 2;

    @media (max-width: 991.98px) {
      margin: 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 0;

      :global(.title-lg) {
        text-align: center;
      }
    }
  }

  .info {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    flex-wrap: wrap;
    gap: 15px;

    @media (max-width: 991.98px) {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 0;
      z-index: 2;

      .moreInfoBtn {
        width: fit-content;
        margin: 0 auto;
      }
    }
  }

  .controls {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &MobileView {
      flex-direction: column;
    }

    @media (max-width: 991.98px) {
      &HistoryBtn {
        width: 100%;

        a {
          width: 100%;
          margin-top: 10px;
        }
      }
    }

    @media (min-width: 991.99px) {
      padding-top: 15px;

      &HistoryBtn {
        a {
          margin-top: 0;
        }
      }

      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: rgba(206, 206, 208, 0.5);
      }
    }
  }

  .mobile {
    width: initial;
    align-items: center;
    flex-direction: column;
    gap: 0;
    position: relative;
    z-index: 2;

    &InfoBlock {
      width: fit-content;
      margin: 0 auto;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      color: #8e8e93;
      max-width: 100%;

      p {
        padding: 0;
        margin: 0;
        width: 100%;
        word-break: break-all;
        text-align: center;
      }

      a {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        text-align: right;
        color: #17a2c6;
      }
    }
  }

  .patientInfoBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 20%;
    width: fit-content;
    word-break: break-all;

    @media (max-width: 991.98px) {
      flex-direction: row;
      width: 100%;
      padding-bottom: 10px;
      padding-top: 10px;
      border-bottom: 1px solid rgba(206, 206, 208, 0.5);

      &:first-of-type {
        padding-top: 0;
        margin-top: 15px;
      }

      & + .moreInfoBtn {
        margin-top: 15px;
      }
    }

    &Title {
      color: #aaa;
      margin: 0;
      white-space: nowrap;

      @media (max-width: 991.98px) {
        flex-grow: 1;
      }
    }

    &Text {
      color: #000;
      margin: 0;
      width: 100%;
      white-space: normal;

      @media (max-width: 991.98px) {
        width: auto;
      }
    }

    &Link {
      width: initial;
      flex-direction: row;
      justify-content: flex-end;
      align-self: flex-end;
      align-items: center;
      margin-left: auto;
      margin-right: 0;

      @media (max-width: 991.98px) {
        width: 100%;
        border-top: 1px solid #d9d9d9;
        padding-top: 15px;
      }

      a {
        height: 48px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.25;
        color: #17a2c6;
        padding-left: 15px;
        transition: background-color 0.3s ease-in-out;
        white-space: nowrap;

        @media (max-width: 991.98px) {
          width: 100%;
          height: initial;
          padding-left: 0;
          border-left: none;
          justify-content: center;
        }
      }
    }
  }
}

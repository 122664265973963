.actorProfile {
  .userPhoto :global(.user_photo) {
    width: 100px;
    min-width: 100px;
    height: 100px;
    font-size: 34px;
    margin: 0 auto 10px;
  }

  .title {
    text-align: center;
    margin-bottom: 5px;
  }
}

.meta {
  text-align: center;
  line-height: 1.25;
  color: #8e8e93;

  &Role,
  &Rating {
    display: inline-block;
  }

  &Rating {
    position: relative;
    margin-left: 10px;
    padding-left: 20px;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      vertical-align: middle;
      content: '';
      width: 20px;
      height: 20px;
      background: url('~ziphy-web-shared/basic/assets/images/ui/star-empty.svg') no-repeat center;
      background-size: cover;
      opacity: 0.39;
    }
  }
}

.modalBody {
  margin-top: -14px;
}

.desc {
  padding-top: 15px;
}

.specialties {
  margin-top: 20px;

  :global {
    .meta-group {
      margin-bottom: -5px;

      .meta {
        margin-bottom: 5px;
      }
    }
  }
}

.desc + .specialties {
  margin-top: 30px;
}

.contacts + .desc {
  padding: 0;
  margin-top: 30px;
}

.contacts {
  position: relative;
  margin-top: 30px;
  margin-left: -5px;
  margin-bottom: -5px;
  text-align: center;

  .contactsItem {
    position: relative;
    margin-left: 5px;
    margin-bottom: 5px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 110px;
    padding: 15px;
    border-radius: 16px;
    background-color: #f6f6f6;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: #f2f3f3;
    }

    &Disabled {
      cursor: default;
      opacity: 0.4;
    }
  }

  &Icon {
    position: relative;
    display: block;
    content: '';
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    background-position: center;

    &Text {
      background-image: url('~ziphy-web-shared/basic/assets/images/ui/text-message.svg');
    }

    &Phone {
      background-image: url('~ziphy-web-shared/basic/assets/images/ui/phone-call.svg');
    }

    :global {
      .progress_button--active {
        opacity: 0;
      }
    }
  }

  &Title {
    font-size: 13px;
    font-weight: 600;
    line-height: 1.54;
  }
}

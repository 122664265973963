.item {
  position: relative;
  padding: 15px;
  border-radius: 16px;
  border: 1px solid #f6f6f6;
  background-color: #f6f6f6;

  & + & {
    margin-top: 10px;
  }

  .redMark {
    position: absolute;
    display: block;

    left: -4px;
    top: -4px;

    width: 14px;
    min-width: 14px;
    height: 14px;

    margin-right: 5px;

    border-radius: 50%;
    background-color: red;
    border: 2px solid #ffffff;

    opacity: 0;
    transition: opacity 0.2s ease;

    &Visible {
      opacity: 1;
    }
  }

  .title {
    color: #8d8e93;
  }

  .text {
    font-weight: 600;
  }

  &Buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 10px;
    margin-top: 10px;

    :global(.btn) {
      border-radius: 8px;
    }
  }

  &Date {
    line-height: 1.25;
    color: #8e8e93;
    margin-top: 10px;
    margin-left: auto;
    padding-left: 15px;
  }

  &Viewed {
    opacity: 0.5;
  }
}

.searchParams {
  .row {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    margin-bottom: -10px;
    margin-top: 10px;

    @media (max-width: 991.98px) {
      margin-top: -10px;
      flex-wrap: wrap;
      .search {
        margin-top: 10px;
      }
    }

    .tabs {
      position: relative;
      width: 100%;
      max-width: 100%;

      @media (max-width: 991.98px) {
        margin-bottom: 10px;
      }
    }

    .buttons {
      text-align: right;

      @media (max-width: 991.98px) {
        padding-right: 0 !important;
        text-align: start;
      }
    }
  }
}

@import './NewDatePicker.modal';
@import './dayPicker/DayPicker';
@import './dayListPicker/DayListPicker';

.new-date-picker {
  width: 405px;
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 16px;

  & > .control {
    display: flex;
    justify-content: space-between;
    height: 30px;
    margin: -30px -30px 0 -30px;

    @media (max-width: 991.98px) {
      & {
        height: 44px;
      }
    }

    .action {
      padding: 8px;
      opacity: 0.25;
      transition: opacity 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      &.back,
      &.close {
        margin-top: 2px;
        width: 44px;
        height: 44px;
      }

      &.back {
        margin-left: 2px;
      }

      &.close {
        margin-left: auto;
        margin-right: 2px;
      }
    }
  }

  & > .header {
    height: 30px;

    .title {
      text-align: center;
    }
  }

  & > .footer {
    margin: 10px -30px -30px -30px;
    border-radius: 0 0 16px 16px;
    box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.15);
    text-align: center;

    .selected-date {
      display: inline-block;
      padding: 20px 20px 30px 20px;
      font-weight: bold;
      line-height: 1.25;
      color: #17a2c6;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
        transition: color 0.3s ease-in-out;
      }

      &:active {
        opacity: 1;
      }

      &.locked {
        color: #cccccc;
        cursor: default;
        opacity: 1;
      }
    }
  }
}

.control {
  display: flex;
  justify-content: space-between;
  height: 30px;
  margin: -30px -30px 0 -30px;

  @media (max-width: 991.98px) {
    & {
      height: 44px;
    }
  }

  .action {
    padding: 8px;
    opacity: 0.25;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &.close {
      width: 44px;
      height: 44px;
      margin-top: 2px;
      margin-left: auto;
      margin-right: 2px;
    }
  }
}

.header {
  height: 30px;

  :global(.title) {
    text-align: left;
  }
}

.footer {
  margin: 10px -30px -30px -30px;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.15);
  text-align: center;

  .selectedSlot {
    display: inline-block;
    padding: 20px 20px 30px 20px;
    font-weight: bold;
    line-height: 1.25;
    color: #17a2c6;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
      transition: color 0.3s ease-in-out;
    }

    &:active {
      opacity: 1;
    }

    &.locked {
      color: #cccccc;
      cursor: default;
      opacity: 1;
    }
  }
}

.notify {
  width: 100%;
  margin-top: 25px;

  padding: 9px 15px;
  border: 1px solid #ffe27f;
  border-radius: 16px;
  background: rgba(255, 226, 127, 0.3);

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  color: #000000;
}

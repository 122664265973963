.CallMe {
  position: relative;
  line-height: 1.25;
  color: #8d8e93;
  user-select: none;

  & > span {
    display: inline-block;
    min-width: 60px;
    position: relative;
    cursor: pointer;
    text-decoration: underline;
  }

  &Loading > span {
    color: transparent;
    cursor: default;
  }

  :global {
    .bubble-loader {
      color: #8d8e93;
      top: -10px;
      left: 27px;
    }
  }
}


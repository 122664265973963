.chat-modal {
  .modal-content {
    height: 100%;

    @media (min-height: 700px) {
      height: 680px;
    }

    @media (min-height: 1000px) {
      height: 742px;
    }
  }

  .chat {
    position: relative;
    overflow-y: scroll !important;

    padding-top: 0;
    padding-bottom: 0;

    &-inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 100%;

      &--fixed {
        max-height: 100%;
      }
    }

    &-messages {
      width: 100%;
      display: flex;
      flex-direction: column;

      padding-top: 20px;
      padding-bottom: 37px;

      position: relative;
    }

    &-message {
      display: flex;
      position: relative;
      max-width: 290px;

      .chat-message-inner {
        padding: 10px 15px;
        font-size: 15px;
        line-height: 1.33;
        background-color: #f2f3f3;
        border-radius: 16px;
        white-space: pre-line;

        span.member {
          display: flex;
          color: #17a2c6;
          font-size: 13px;
          font-weight: bold;
          line-height: 1.54;
        }
      }

      .red_marker {
        align-self: flex-end;
      }

      &--danger {
        .chat-message-inner {
          color: #ffffff;
          background-color: #ff3b30;
        }
      }

      &--left {
        align-self: flex-start;

        .chat-message-inner {
          border-bottom-left-radius: 3px;
        }
      }

      &--right {
        align-self: flex-end;

        .chat-message-inner {
          text-align: left;

          color: #ffffff;
          background-color: #17a2c6;
          border-bottom-right-radius: 3px;
        }
      }
    }

    .chat-message--left + .chat-message--left,
    .chat-message--right + .chat-message--right {
      padding-top: 5px;
    }

    .chat-message--left + .chat-message--right,
    .chat-message--right + .chat-message--left {
      padding-top: 5px;
    }

    .chat-status {
      position: absolute;
      left: 15px;
      bottom: 10px;
      font-size: 14px;
      margin-top: 5px;
      color: #8e8e93;

      .bubble-loader {
        right: -12px;
        top: -8px;
      }
    }

    .chat-empty {
      position: absolute;
      bottom: 30px;
      text-align: center;

      transition: opacity 0.15s ease-in-out;

      user-select: none;

      &--content {
        position: relative;
        bottom: initial;
        padding-bottom: 10px;
      }

      &--hide {
        opacity: 0;
      }
    }
  }

  .modal-footer {
    box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;

    z-index: 1;
  }

  &--with-input {
    .modal-footer {
      padding-top: 17px;
      padding-bottom: 17px;
    }

    .chat-input {
      position: relative;
      display: flex;
      align-items: flex-end;

      &-field {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        border: none;
        outline: none;

        line-height: 1.5;
        font-weight: 600;
        color: #000000;
        background: transparent;

        resize: none;

        &::placeholder {
          color: #d1d1d6;
        }
      }

      &-btn {
        position: relative;
        width: 44px;
        height: 44px;
        padding: 8px;
        margin-top: -10px;
        margin-right: -8px;
        margin-bottom: -10px;
        border: none;
        outline: none;
        cursor: pointer;
        background: url('~ziphy-web-shared/basic/assets/images/ui/send.svg') no-repeat center;

        &:disabled {
          opacity: 0.3;
          filter: grayscale(100%);
        }

        &:focus,
        &:active {
          outline: none;
        }
      }
    }
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.chart {
  width: 100%;
  max-width: calc(33.3% - 20px / 3);
  margin-bottom: 10px;

  @media (max-width: 1100px) {
    max-width: calc(50% - 10px / 2);
  }

  @media (max-width: 991.98px) {
    max-width: calc(33.3% - 20px / 3);
  }

  @media (max-width: 821px) {
    max-width: calc(50% - 10px / 2);
  }

  @media (max-width: 575.98px) {
    max-width: 100%;
  }
}

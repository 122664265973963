.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  min-width: 28px;
  height: 28px;
  cursor: pointer;

  .icon {
    opacity: 0;
    width: 28px;
    min-width: 28px;
    height: 28px;
  }

  &.small {
    width: 20px;
    min-width: 20px;
    height: 20px;

    .icon {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }
  }

  &.checked {
    .icon {
      opacity: 1;
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}

.checkbox.round {
  border-radius: 50%;
  background-color: #f6f6f6;

  &.dark {
    background-color: rgba(216, 216, 218, 0.45);
  }

  &.multi:not(&.checked) {
    background-color: #ffffff;

    .icon {
      opacity: 0.25;
    }
  }

  &.checked {
    color: #ffffff;
    background-color: #17a2c6;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.checkbox.square {
  background-color: #ffffff;
  border: 1px solid #E3E3E5;
  border-radius: 10px;

  &.small {
    border-radius: 6px;
  }

  &.dark {
    //
  }

  &.multi:not(&.checked) {
    .icon {
      opacity: 0.25;
    }
  }

  &.checked {
    color: #17a2c6;

    .icon {
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.3;
  }
}

@import '@assets/styles/mixins';

.btn {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  padding: 13px 15px 13px 15px;
  border-radius: 16px;
  min-width: 110px;

  display: inline-block;
  outline: none;
  border: none;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;

  transition: color 0.3s ease-in-out,
  background-color 0.3s ease-in-out;

  &:focus {
    outline: none;
  }
}

.btn.btn-sm {
  border-radius: 12px;
  padding: 9px 15px;
  font-weight: 600;
}

.btn.btn-xs {
  border-radius: 12px;
  padding: 3px 10px;
  font-weight: 600;
  min-width: initial;
}

.btn.btn-outline {
  padding: 12px 14px 12px 14px;
  border: 1px solid transparent;

  font-weight: 600;

  .btn.btn-sm {
    padding: 7px 14px;
  }

  .btn.btn-xs {
    padding: 2px 9px;
  }
}

.btn-group {
  margin-left: -10px;

  .btn {
    margin-left: 10px;
  }

  .btn-block {
    max-width: calc(100% - 10px);
  }

  &--inline {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;

    .btn {
      flex: 1;
      margin-bottom: 10px;
    }
  }

  &--column {
    margin-bottom: -10px;

    .btn {
      margin-bottom: 10px;
    }
  }
}

.btn.btn-primary {
  color: #ffffff;
  background-color: #17a2c6;

  &:hover,
  &:focus {
    color: #ffffff;
    background-color: #1386a4;
  }

  &.disabled,
  &[disabled] {
    &:not(.progress_button--active) {
      color: #d1d1d6;
      background-color: #f2f3f3;

      &:hover,
      &:focus {
        background-color: #f2f3f3;
      }
    }
  }

  &.btn-outline {
    color: #17a2c6;
    border-color: #e5e5e5;
    background-color: #fbfbfb;

    &:hover,
    &:focus:hover {
      color: #17a2c6;
      background-color: #f6f6f6;
    }

    &:focus {
      color: #17a2c6;
      background-color: #fbfbfb;
    }

    &.disabled,
    &[disabled] {
      &:not(.progress_button--active) {
        color: #d1d1d6;

        &:hover,
        &:focus {
          color: #d1d1d6;
          background-color: #f6f6f6;
        }
      }
    }
  }
}

.btn.btn-secondary {
  color: #17a2c6;
  background-color: #f2f3f3;

  &:hover,
  &:focus {
    color: #17a2c6;
    background-color: #f0f0f0;
  }

  &.disabled,
  &[disabled] {
    &:not(.progress_button--active) {
      color: #d1d1d6;

      &:hover,
      &:focus {
        color: #d1d1d6;
        background-color: #f2f3f3;
      }
    }
  }
}

.btn.btn-secondary-danger {
  color: #ff3b30;
  background-color: #f2f3f3;

  &:hover,
  &:focus {
    color: #ff3b30;
    background-color: #f0f0f0;
  }

  &.disabled,
  &[disabled] {
    &:not(.progress_button--active) {
      color: #d1d1d6;

      &:hover,
      &:focus {
        color: #d1d1d6;
        background-color: #f2f3f3;
      }
    }
  }
}

.btn.btn-success {
  color: #ffffff;
  background-color: #4cd964;

  &:hover,
  &:focus {
    color: #ffffff;
    background-color: #3fb253ff;
  }

  &.disabled,
  &[disabled] {
    &:not(.progress_button--active) {
      background-color: #f2f3f3;

      &:hover,
      &:focus {
        background-color: #f2f3f3;
      }
    }
  }

  &.btn-outline {
    color: #17a2c6;
    border-color: #e5e5e5;
    background-color: #fbfbfb;

    &:hover,
    &:focus:hover {
      color: #17a2c6;
      background-color: #f6f6f6;
    }

    &:focus {
      color: #17a2c6;
      background-color: #fbfbfb;
    }

    &.disabled,
    &[disabled] {
      &:not(.progress_button--active) {
        color: #d1d1d6;

        &:hover,
        &:focus {
          color: #d1d1d6;
          background-color: #f6f6f6;
        }
      }
    }
  }
}

.btn.btn-white {
  color: #17a2c6;
  background-color: #ffffff;

  &:hover,
  &:focus {
    color: #1386a4;
    background-color: #fdfdfd;
  }

  &.disabled,
  &[disabled] {
    &:not(.progress_button--active) {
      opacity: 0.7;

      &:hover,
      &:focus {
        background-color: #ffffff;
      }
    }
  }
}

.btn.btn-white-dark {
  color: #000000;
  background-color: #ffffff;

  &:hover,
  &:focus {
    color: #000000;
    background-color: #fdfdfd;
  }

  &.disabled,
  &[disabled] {
    &:not(.progress_button--active) {
      opacity: 0.7;

      &:hover,
      &:focus {
        background-color: #ffffff;
      }
    }
  }
}

.btn.btn-black {
  color: #fff;
  background-color: #000;

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.7);
  }

  &.disabled,
  &[disabled] {
    &:not(.progress_button--active) {
      opacity: 0.7;

      &:hover,
      &:focus {
        background-color: #000;
      }
    }
  }
}

.btn.btn-ghost {
  color: #000000;
  background-color: transparent;
  border: 1px solid #fff;
  height: 50px;

  &:hover,
  &:focus {
    color: #000000;
    background-color: #fdfdfd;
  }

  &.disabled,
  &[disabled] {
    &:not(.progress_button--active) {
      opacity: 0.7;
      background-color: #fdfdfd;

      &:hover,
      &:focus {
        background-color: #ffffff;
      }
    }
  }
}

.btn.btn-danger {
  color: #ffffff;
  background-color: #ff3b30;

  &:hover,
  &:focus {
    color: #ffffff;
    background-color: #e33329;
  }

  &.disabled,
  &[disabled] {
    &:not(.progress_button--active) {
      background-color: #f2f3f3;

      &:hover,
      &:focus {
        background-color: #f2f3f3;
      }
    }
  }
}

.btn.btn-secondary-dark {
  color: #000000;
  background-color: #f6f6f6;

  &:hover,
  &:focus,
  &.btn--active {
    color: #000000;
    background-color: #f0f0f0;
  }

  &.disabled,
  &[disabled] {
    &:not(.progress_button--active) {
      opacity: 0.5;

      &:hover,
      &:focus {
        background-color: #f6f6f6;
      }
    }
  }
}

.btn.btn-blue {
  border-radius: 20px;
  min-width: 1px;

  color: #17a2c6;
  background-color: #e7f5f9;

  &:hover,
  &:focus,
  &.btn--active {
    color: #17a2c6;
    background-color: #d1eef4;
  }

  &.disabled,
  &[disabled] {
    &:not(.progress_button--active) {
      color: #9cd7e6;

      &:hover,
      &:focus {
        background-color: #f6fafb;
      }
    }
  }
}

// remove and use secondary-gray
.btn.btn-gray {
  color: #ffffff;
  background-color: #6d6d6d;

  &:hover,
  &:focus {
    color: #ffffff;
    background-color: #656565;
  }

  &.disabled,
  &[disabled] {
    &:not(.progress_button--active) {
      opacity: 0.7;

      &:hover,
      &:focus {
        background-color: #6d6d6d;
      }
    }
  }
}

.btn.btn-create {
  color: #000000;
  background-color: #f6f6f6;

  &:before {
    background-image: url('~ziphy-web-shared/basic/assets/images/ui/plus.svg');
  }

  &:hover,
  &:focus {
    color: #000000;
    background-color: #f0f0f0;
  }

  &.disabled,
  &[disabled] {
    &:not(.progress_button--active) {
      opacity: 0.7;

      &:hover,
      &:focus {
        background-color: #f6f6f6;
      }
    }
  }
}

.btn.btn-filter {
  &:before {
    background-image: url('~ziphy-web-shared/basic/assets/images/ui/filters-list.svg');
    opacity: 0.25;
  }
}

.btn.btn-more {
  min-width: initial;

  &:before {
    background-image: url('~ziphy-web-shared/basic/assets/images/ui/more-dots.svg');
  }
}

.btn.btn-more,
.btn.btn-filter,
.btn.btn-link,
.btn.btn-create,
.btn.btn-icon {
  position: relative;

  &:before {
    position: relative;
    display: inline-block;
    content: '';
    width: 28px;
    height: 28px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 5px;
    margin-bottom: -15px;
    transform: translateY(-7px);
  }

  &:empty {
    min-width: auto;

    &:before {
      margin-right: 0;
    }
  }
}

.btn.btn-icon {
  &:empty {
    padding-left: 11px;
    padding-right: 11px;
  }

  &:before {
    background-image: url('~ziphy-web-shared/basic/assets/images/ui/plus.svg');
  }
}

.btn.btn-link {
  color: #000000;
  font-weight: 600;
  padding: 0;

  &:before {
    background-image: url('~ziphy-web-shared/basic/assets/images/ui/plus.svg');
  }
}

.btn.btn-anchor {
  color: #17a2c6;
  padding: 0;
  margin: 0;
  min-width: initial;
  border-radius: initial;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #1386a4;
    text-decoration: none;
  }

  &.progress_button--active {
    .progress_button-indicator {
      background-color: white;
    }
  }
}

.btn.btn-anchor-gray {
  color: #8d8e93;
  padding: 0;
  margin: 0;
  min-width: initial;
  border-radius: initial;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #8e8e93;
    text-decoration: none;
  }

  &.progress_button--active {
    .progress_button-indicator {
      background-color: white;
    }
  }
}

.btn.btn-anchor-dashed {
  color: #17a2c6;
  padding: 0;
  margin: 0;
  min-width: initial;
  border-radius: initial;
  transition: color 0.3s ease-in-out, border-bottom-color 0.3s ease-in-out;
  cursor: pointer;
  border-bottom: 1px dashed #17a2c6;

  &:hover,
  &:focus {
    color: #1386a4;
    text-decoration: none;
    border-bottom-color: #1386a4;
  }

  &.progress_button--active {
    .progress_button-indicator {
      background-color: white;
    }
  }
}


.btn.btn-anchor-gray-dashed {
  color: #8d8e93;
  padding: 0;
  margin: 0;
  min-width: initial;
  border-radius: initial;
  transition: color 0.3s ease-in-out, border-bottom-color 0.3s ease-in-out;
  cursor: pointer;
  border-bottom: 1px dashed #8d8e93;

  &:hover,
  &:focus {
    color: #8e8e93;
    text-decoration: none;
    border-bottom-color: #8d8e93;
  }

  &.progress_button--active {
    .progress_button-indicator {
      background-color: #8e8e93;
    }
  }
}

.btn.btn-block {
  display: block;
}

button,
[type='submit'],
[type='button'] {
  &.btn-block {
    width: 100%;
  }
}

.btn.disabled,
.btn[disabled] {
  pointer-events: none;
  cursor: default;
}

.progress_button {
  position: relative;

  &-indicator {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    border-radius: inherit;
    background-color: inherit;
    //background-repeat: no-repeat;
    //background-position: center;
    //background-size: contain;
  }

  &--active {
    &.disabled,
    &[disabled] {
      pointer-events: none;
    }

    // make everything except loader transparent
    & > *:not(:first-child) {
      opacity: 0;
    }

    .progress_button-indicator {
      display: flex;
    }
  }
}

.btn.btn-fw-400 {
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}

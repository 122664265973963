.wrapper {
  position: relative;

  display: flex;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;

  .text {
    font-size: 14px;
    line-height: 20px;
    padding: 0 5px;
  }

  .left, .right {
    flex: 1;
    height: 1px;
    background-color: currentColor;
  }
}

.wrapper.gray {
  color: #e8e8e8;
}

.wrapper {
  position: relative;
}

.wrapper.inlineDashed {
  display: inline-block;
  color: currentColor;

  &.hasTooltip {
    cursor: pointer;
    position: relative;
    text-decoration: underline dashed;
  }
}

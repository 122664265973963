.wrapper {
  position: relative;
  width: 100%;

  &Readonly {
    cursor: default;
    pointer-events: none;

    .dropdownWrapper {
      display: none;
    }
  }
}

.dropdownWrapper {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: fixed;
  max-height: 360px;
  width: 100%;
  z-index: -9999;
  opacity: 0;
  pointer-events: none;

  &Open {
    opacity: 1;
    z-index: 9999;
    pointer-events: initial;
  }
}

.linksWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;

  :global(.box-header-link) {
    margin-left: 10px;
  }

  :global(.box-header-link + .box-header-link) {
    position: relative;
    padding-left: 11px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 1px;
      height: 100%;
      background: rgba(0, 0, 0, 0.16);
    }
  }
}

.day-picker-year {
  height: 428px;
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 30px;
  padding-right: 30px;

  & > .body {
    padding-top: 16px;

    .row-year {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 2px;

      &:after {
        content: '';
        flex: auto;
      }

      .cell {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 84px;
        height: 48px;
        font-size: 16px;
        border-radius: 16px;
        background-color: #ffffff;
        cursor: pointer;
        user-select: none;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

        @media (max-width: 767.98px) {
          font-size: 18px;
        }

        &:hover {
          background-color: #f6f6f6;
        }

        &.cell-selected {
          font-weight: 600;
          color: #ffffff;
          background-color: #17a2c6;
        }

        &.cell-current {
          font-weight: 600;
          color: #17a2c6;

          &.cell-selected {
            color: #ffffff;
          }
        }

        &.cell-locked {
          color: #cccccc;
          cursor: default;

          &.cell-selected {
            color: #ffffff;
            background-color: #cccccc;

            &.cell-current {
              color: #ffffff;
            }
          }

          &.cell-current {
            color: #17a2c6;
            opacity: 0.5;
          }

          &:not(.cell-selected):hover {
            background-color: #ffffff;
          }
        }
      }
    }
  }
}

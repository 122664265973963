$tooltip-background-color: rgba(38, 38, 38, 0.7);
$tooltip-arrow-size: 6px;

.wrapper {
  display: inline-block;

  &_relative {
    position: relative;
  }
}

.tip {
  position: absolute;
  left: 50%;
  z-index: auto;
  height: fit-content;

  width: 191px;
  display: flex;
  justify-content: center;

  .textWrapper {
    border-radius: 10px;
    padding: 10px 15px;
    background: $tooltip-background-color;
    max-width: 100%;
    backdrop-filter: blur(2px);
    font-size: 13px;
    line-height: 1.53;
    color: white;
  }

  &::before {
    content: '';

    position: absolute;
    left: 50%;

    width: 0;
    height: 0;
    pointer-events: none;
    border: $tooltip-arrow-size solid transparent;
    margin-left: calc($tooltip-arrow-size * -1);
    //backdrop-filter: blur(2px);
  }
}

.top {
  transform: translate(-50%, calc(-1 * (100% + $tooltip-arrow-size)));

  &::before {
    top: 100%;
    border-top-color: $tooltip-background-color;
  }
}

.right {
  transform: translate(calc($tooltip-arrow-size), -50%);
  justify-content: flex-start;

  &::before {
    left: calc(($tooltip-arrow-size * -1) + 1px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: $tooltip-background-color;
  }
}

.bottom {
  transform: translate(-50%, calc($tooltip-arrow-size));
  justify-content: center;

  &::before {
    bottom: 100%;
    border-bottom-color: $tooltip-background-color;
  }
}

.left {
  transform: translate(calc(-1 * (100% + $tooltip-arrow-size)), -50%);
  justify-content: flex-end;

  &::before {
    left: auto;
    top: 50%;
    right: calc(($tooltip-arrow-size * -2) + 1px);
    transform: translateX(0) translateY(-50%);
    border-left-color: $tooltip-background-color;
  }
}

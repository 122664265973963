.radioTabs {
  position: relative;
  display: flex;

  &Default {
    padding: 3px;
    border-radius: 16px;
    background-color: #f2f3f3;

    .item {
      flex-grow: 1;
      flex-basis: 0;
      padding: 11px 20px;
      font-weight: 600;
      line-height: 1.25;
      border-radius: 13px;

      &Active {
        background-color: #ffffff;
      }

      & + & {
        margin-left: 3px;
      }
    }
  }

  &Modern {
    padding: 5px;
    border-radius: 12px;
    background-color: rgba(234, 234, 234, 1);

    .item {
      flex-grow: 1;
      flex-basis: 0;
      padding: 7px 17.5px;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      border-radius: 8px;
      color: rgba(142, 142, 147, 1);

      &Active {
        background-color: #ffffff;
        color: #000000;
      }
    }
  }

  &Dark {
    margin-left: -10px;

    &:before,
    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
    }

    &:before {
      width: 10px;
      left: 0;
      z-index: 1;
      background-color: #ffffff;
    }

    &:after {
      width: 30px;
      right: 0;
      background-image: linear-gradient(to left, #fff 23%, rgba(255, 255, 255, 0));
    }

    .scroll {
      padding-right: 20px;
    }

    .item {
      padding: 8px 15px 8px;
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      border-radius: 10px;
      background-color: #f6f6f6;

      &Active {
        background-color: #000000;
        color: #ffffff;
      }

      &Sm {
        padding: 5px 15px;
        font-size: 14px;
        line-height: 20px;
      }

      &Lg {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &Light {
    &:after {
      display: block;
      content: '';
      width: 30px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: linear-gradient(to left, #fff 23%, rgba(255, 255, 255, 0));
    }

    .scroll {
      padding-right: 20px;
    }

    .item {
      padding: 8px 10px 8px;
      border-radius: 10px;

      &Active {
        background-color: #f6f6f6;
      }
    }
  }
}

.scroll {
  display: flex;
  overflow-x: scroll;
  width: calc(100% + 40px);

  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
}

.item {
  text-align: center;
  cursor: pointer;
  white-space: nowrap;

  &Active {
    cursor: default;
  }

  &Disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
}

.count {
  display: inline-block;
  margin-left: 5px;
  color: #999;
}

.submit {
  position: relative;
  display: flex;
  margin-bottom: 20px;

  @media (max-width: 991.98px) {
    display: block;
  }

  .location {
    min-width: auto;

    @media (max-width: 991.98px) {
      position: absolute;
      left: 10px;
      top: calc(100% + 30px);
      z-index: 1;
      background-color: #fff;
      width: 38px;
      min-width: 38px !important;
      height: 38px;
      min-height: 38px !important;
      border-radius: 11px;
    }
  }

  .submitButton {
    margin-left: 10px;

    @media (max-width: 991.98px) {
      margin-left: auto;
      min-width: 100%;
    }
  }

  :global {
    .search {
      min-height: 50px;
    }

    .flag_selector {
      position: relative;
      margin-left: 10px;
      width: 53px;
      height: 50px;
      background-color: #f2f3f3;
      border-radius: 16px 0 0 16px;

      @media (min-width: 992px) {
        &:after {
          display: block;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          content: '';
          width: 1px;
          height: 28px;
          background-color: #e0e0dd;
          z-index: 1;
        }

        & .flag-icon {
          margin-left: -8px;
        }

        & .flag_selector-all {
          margin-left: -11px;
        }

        & + .search {
          margin-left: -5px;

          .search-item {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      @media (max-width: 991.98px) {
        width: 38px;
        height: 38px;
        border-radius: 11px;
        position: absolute;
        left: calc(10px + 38px + 10px);
        top: calc(100% + 30px);
        z-index: 1;
        background-color: #fff;
      }
    }

    .search {
      flex-grow: 1;

      &-item:before {
        opacity: 1;
        background-image: url('~ziphy-web-shared/basic/assets/images/ui/location-marker.svg');
      }
    }
  }
}

.content {
  align-items: center;
  display: inline-flex;
  margin-right: auto;
  max-width: 100%;
  min-width: 1px;

  :global(.user_photo) {
    margin-right: 10px;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 1px;
  align-items: flex-start;

  .text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;

    &.withMoreInfo {
      line-height: 1.1;
    }
  }

  .moreInfoText {
    color: #8e8e93;
    font-size: 13px;
    line-height: 18px;
  }

  .deceasedBadge {
    position: relative;
  }
}

.additionalInfo {
  flex-grow: 0;
  margin-left: 15px;
  min-width: 1%;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.mobileView {
  @media (max-width: 991.98px) {
    &.content {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
    }

    :global(.user_photo) {
      margin-right: 0;
      z-index: 1;
      order: 1;
    }

    .textContainer {
      order: 3;

      .text {
        z-index: 1;
        margin-bottom: 10px;
      }
    }

    .deceasedBadge {
      align-self: center;
      margin-top: 0;
    }

    .additionalInfo {
      order: 3;
      margin-top: -10px;
      margin-left: 0;
    }
  }
}

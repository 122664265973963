@import '@assets/styles/_mixins.scss';

.card {
  position: relative;

  display: flex;
  flex-direction: column;

  padding: 10px 15px 15px;
  background: #f6f6f6;
  border-radius: 4px;

  width: 140px;
  min-width: 140px;
  height: 155px;
  overflow: hidden;

  cursor: default;
  pointer-events: none;

  &Clickable {
    cursor: pointer;
    pointer-events: auto;
  }

  &Inner {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    border: 2px solid #f6f6f6;
    border-radius: 4px;

    &:before {
      display: block;
      content: '';
      width: 50px;
      height: 50px;
      background: #ffffff;
      border: 2px solid #f6f6f6;

      position: absolute;
      right: -33px;
      top: -33px;
      transform: rotate(45deg);
    }

    &:after {
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-bottom: 21px solid rgba(0, 0, 0, 0.03);
      border-right: 21px solid transparent;

      position: absolute;
      right: -2px;
      top: -2px;
    }
  }

  &Title {
    padding-right: 15px;
    font-weight: 600;
    line-height: 1.25;
    color: #000000;

    &Gray {
      color: #8e8e93;
    }

    @include line-clamp(3);
  }

  &Meta {
    margin-top: auto;
    padding-top: 10px;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.54;
    color: #8e8e93;
    white-space: pre-line;
  }
}

.status {
  display: flex;
  align-items: center;
  gap: 6px;

  &Point {
    width: 16px;
    height: 16px;
    background-color: #ff3824;
    border-radius: 50%;
  }

  &Default {
    color: #8e8e93;
  }

  &Error {
    color: #ff3a30;
  }
}

.icon {
  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.loader {
  flex: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-left: 8px;
}

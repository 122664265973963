.container {
  margin: 30px 0;
}

.title {
  margin-bottom: 10px;
}

.documentsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 1089px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .document {
      width: auto;
      border-radius: 14px;
    }
  }

  @media (max-width: 692px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 490px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 332px) {
    grid-template-columns: 1fr;
  }

  .document {
    margin-left: 0;
  }

  .error .document {
    cursor: pointer;
    pointer-events: auto;
  }
}

.previewBlock {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 15px;
  background-color: rgba(23, 162, 198, 0.2);
  border-radius: 12px 12px 12px 2px;

  cursor: pointer;

  .placeholder {
    color: #17a2c6;
    white-space: nowrap;
  }
}

.icon {
  cursor: pointer;
  color: #17a2c6;
}

.wrapper {
  position: relative;

  width: fit-content;
  min-width: 150px;
  min-height: 48px;

  display: flex;
  align-items: flex-start;
  gap: 10px;

  background-color: rgba(23, 162, 198, 0.2);
  border-radius: 12px 12px 12px 2px;

  .textareaWrapper {
    position: relative;

    width: 100%;
    border-radius: 12px;

    padding: 10px 15px;

    &PR {
      padding-right: 58px;
    }

    .label {
      font-size: 13px;
      line-height: 18px;
      color: #8e8e93;
      display: block;

      margin: 0;
      padding: 0;
    }

    .valuePreview {
      white-space: pre-wrap;
      line-height: 1.5;
      font-weight: 400;
      color: #000000;
      width: 100%;
      display: -webkit-box;
      word-break: break-all;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .textarea {
      min-height: 24px;
      width: calc(100% - 43px);
      padding: 0;
      margin: 0;
      display: block;
      border: none;
      outline: none;

      line-height: 1.5;
      font-weight: 400;
      color: #000000;
      background: transparent;

      resize: none;
    }

    .clear,
    .iconEdit {
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 18px;
      color: #17a2c6;
    }

    .loaderWrapper {
      position: absolute;
      right: 25px;
      top: 13px;

      .loader {
        color: #17a2c6;
      }
    }
  }

  &Expanded {
    background-color: transparent;
    width: 100%;

    .textareaWrapper {
      width: calc(100% - 66px);
      border: 1px solid #e6e6e7;
    }

    .controlBlock {
      position: relative;
      top: 17px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &.loading {
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 5;
    }
  }
}

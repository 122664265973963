.apptsSearch {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  flex-wrap: wrap;

  .tabs {
    flex-grow: 1;

    @media (max-width: 767.98px) {
      flex: 1;
    }
  }

  .practiceSelect {
    min-width: 33.33333%;
    max-width: 33.33333%;

    @media (max-width: 767.98px) {
      flex: 1;
      min-width: 100%;
      max-width: 100%;
      order: -1;
    }
  }
}

.encountersControl {
  min-height: 50px;
  z-index: 2;
  display: flex;
  gap: 10px;
  align-items: center;
  color: #8e8e93;
  font-weight: 700;
  white-space: nowrap;
  justify-content: center;

  @media (max-width: 991.98px) {
    position: relative;
    min-height: initial;
    top: 0;
    right: 0;
    margin-top: 10px;
    width: 100%;
    :global {
      .btn:first-of-type {
        flex-grow: 1;
      }
    }
  }

  &Visible {
    @media (max-width: 991.98px) {
      min-height: 50px;
    }
  }

  :global {
    .btn.btn-secondary-dark {
      &.disabled,
      &[disabled] {
        padding-right: 0;
      }
    }
  }
}

.breadcrumbs {
  width: 100%;
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  flex-wrap: wrap;

  &__badge {
    white-space: nowrap;
  }
}

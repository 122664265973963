.patientItem {
  & + & {
    margin-top: 10px;
  }

  &Details + &,
  & + &Details,
  &Details + &Details {
    margin-top: 30px;
  }
}

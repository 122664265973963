.cursorPointer {
  cursor: pointer;
}

.inner {
  white-space: initial;

  :empty {
    display: none
  }
}

.expanded {
  white-space: pre-line;
  overflow: hidden;
}

@for $i from 1 through 20 {
  .lines#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.button {
  display: inline-block;
}

.container {
  position: relative;

  padding: 10px 15px;
  border-radius: 16px;

  background-color: #f6f6f6;
  cursor: pointer;

  transition: 0.3s ease-in-out background-color;

  &:hover {
    background-color: #f0f0f0;
  }
}

.label {
  color: #8e8e93;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.cardNumber {
  display: flex;
  align-items: center;

  margin-top: 2px;

  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  .icon {
    width: 28px;
    min-width: 28px;
    height: 20px;
    margin-right: 6px;
  }
}

.bubbleLoader {
  display: flex;
  align-items: center;

  height: 20px;
  margin-left: 15px;

  opacity: 0.2;
}

.editIcon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);

  opacity: 0.2;
}

.group {
  margin-left: -20px;
  margin-right: -20px;

  &:first-child {
    margin-top: -20px;
  }

  padding: 20px 20px 0;

  &Open {
    margin-bottom: 10px;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .title {
    display: flex;
    align-items: center;
    margin-right: auto;

    .text {
      font-size: 13px;
      font-weight: normal;
      line-height: 1.54;
      color: #8e8e93;
    }

    .redMark {
      position: relative;
      display: inline-block;
      width: 14px;
      min-width: 14px;
      height: 14px;

      margin-right: 5px;

      border-radius: 50%;
      background-color: red;
      border: 2px solid #ffffff;

      transition: opacity 0.2s ease, margin-left 0.2s ease 0.1s, margin-bottom 0.2s ease;

      &Secondary {
        margin-left: calc(-14px - 5px);
        margin-bottom: -14px;
        opacity: 0;
      }
    }

    .apptLink {
      display: flex;
      align-items: center;

      svg {
        margin-left: -2px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .markAllAsRead {
    font-size: 13px;
    font-weight: normal;
    line-height: 1.54;
    color: #17a2c6;

    transition: color 0.3s ease-in-out;
    cursor: pointer;

    &:hover,
    &:focus {
      color: #1386a4;
    }
  }
}

.showMoreButton {
  &Disabled {
    opacity: 0.5;
  }
}


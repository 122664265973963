.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 10px;
  opacity: 0.3;
}

.notFound {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 10px;
  padding: 10px 15px;
  background: #f6f6f6;
  border-radius: 15px;
  font-weight: 600;
}

.item {
  display: inline-flex;
  align-items: flex-start;
  font-weight: 600;
  line-height: 1.25;
  color: #000000;
  padding: 6px 10px;
  border-radius: 10px;
  transition: background-color 0.2s ease-in-out;

  &:hover, &:focus {
    color: #000000;
  }

  & + & {
    margin-top: 8px;
  }

  &:global(.active) {
    background-color: #f0f0f0;
    cursor: default;
  }

  .icon {
    margin-right: 10px;
    color: #17a2c6;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .text {
    padding-top: 3px;
    padding-bottom: 5px;
    padding-right: 10px;
  }

  .counter {
    margin-left: auto;
    padding: 4px 10px;
    opacity: 0.5;
    min-width: 28px;
    border-radius: 16px;
    background-color: #8e8e93;
    line-height: 1.25;
    text-align: center;
    color: #ffffff;
  }
}

.addAddress {
  .btns {
    display: flex;
    gap: 10px;
  }

  &.blocked {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
    }
  }
}

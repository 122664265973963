.popupNotices {
  $paddingLR: 10px;

  position: fixed;
  z-index: 1060;
  top: 0;
  right: 0;
  width: 100%;
  max-width: calc(314px + #{$paddingLR} * 2);
  padding: 0 $paddingLR 10px;

  &Item {
    position: absolute;
    top: 0;

    width: 100%;
    max-width: calc(100% - #{$paddingLR} * 2);

    border-radius: 16px;
    padding: 15px;
    margin-top: 10px;

    box-shadow: 0 2px 8px 0 rgba(0, 17, 77, 0.17);
    background-color: rgba(0, 0, 0, 0.7);

    color: #ffffff;

    .body {
      font-size: 14px;
      line-height: 1.43;

      p {
        font-size: initial;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        font-weight: 600;
        color: #75c3d7;
      }
    }
  }

  &Tolbar {
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    color: #ffffff;
    margin-bottom: 10px;

    &Icon {
      color: #ffffff;
      margin-right: 5px;
      opacity: 0.34;
    }

    &Title {
      margin-right: 5px;
      opacity: 0.34;
    }

    &Close {
      margin-left: auto;
      color: #ffffff;
      opacity: 0.25;
      cursor: pointer;
    }
  }

  &Title {
    font-weight: 600;
    line-height: 1.25;
    color: #ffffff;
    margin-bottom: 5px;
  }

  &Buttons {
    margin-top: 15px;
    //margin-left: -5px;
    margin-bottom: -5px;

    :global(.btn) {
      //margin-left: 5px;
      margin-bottom: 5px;
    }
  }

  &Dismiss {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    cursor: pointer;

    border-radius: 16px;
    padding: 10px 15px;
    margin-top: 25px;

    box-shadow: inherit;
    background-color: inherit;

    color: #8e8e93;
    font-weight: 600;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.56;
    color: #000000;
    margin: 0 5px 0 0;
  }
}

.iconsContainer {
  margin-right: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  width: 28px;
  height: 28px;
  opacity: 0.2;
  cursor: pointer;
  transition: opacity linear 0.1s;

  &:hover {
    opacity: 1;
    transition: opacity linear 0.1s;
  }
}

.modal-open {
  body {
    overflow: hidden;
  }

  &.isWindows body,
  &.isWindows .nearest_appt,
  &.isWindows .swapper_bar {
    padding-right: 17px;
  }

  &.isWindows .swapper_bar-btn_resize {
    left: calc(50% - 17px / 2);
  }
}

.modal-root {
  position: relative;
  z-index: 1040;
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.52);

  &--hidden {
    background-color: transparent;
  }

  @media (max-width: 991.98px) {
    display: none;
  }
}

.modal-blocked-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0;

  @media (min-width: 992px) {
    margin: 60px auto;
    min-height: calc(100% - 60px * 2);
  }

  @media (min-width: 992px) and (max-height: 1000px) {
    margin-top: 40px;
    margin-bottom: 40px;

    min-height: calc(100% - 40px * 2);
  }

  @media (min-width: 992px) and (max-height: 800px) {
    margin-top: 24px;
    margin-bottom: 24px;

    min-height: calc(100% - 24px * 2);
  }

  @media (max-width: 991.98px) {
    height: 100%;
  }

  &,
  &--md {
    @media (min-width: 992px) {
      max-width: 405px;
    }
  }

  &--sm {
    @media (min-width: 992px) {
      max-width: 320px;
    }
  }

  &--lg {
    @media (min-width: 992px) {
      max-width: 700px;
    }
  }

  &--xl {
    @media (min-width: 992px) {
      max-width: 820px;
    }
  }

  &--xxl {
    @media (min-width: 992px) {
      max-width: 970px;
    }
  }

  &--centered {
    @media (min-width: 992px) {
      display: flex;
      align-items: center;
    }
  }

  &--scrollable {
    height: calc(100% - 60px * 2);

    @media (max-height: 1000px) {
      height: calc(100% - 40px * 2);
    }

    @media (max-height: 800px) {
      height: calc(100% - 24px * 2);
    }

    @media (max-width: 991.98px) {
      height: 100%;
    }

    .modal-content {
      max-height: 100%;
      overflow: hidden;
    }

    .modal-body {
      max-height: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  .modal-header--bordered,
  &--header-bordered .modal-header {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(206, 206, 208, 0.5);
  }

  .modal-footer--shadow,
  &--footer-shadow .modal-footer {
    box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.15);
    z-index: 1;
  }

  .modal-footer--bordered,
  &--footer-bordered .modal-footer {
    padding-top: 9px;
    border-top: 1px solid #e0e0dd;
  }

  @media (min-width: 992px) {
    &--height-auto {
      .modal-content {
        min-height: initial;
      }
    }
  }

  @media (min-width: 992px) {
    &--full-height {
      .modal-content {
        min-height: 100%;
      }
    }
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  //min-height: 272px;
  pointer-events: auto;
  background-color: #ffffff;
  border-radius: 16px;

  @media (max-width: 991.98px) {
    border-radius: 0;
    min-height: 100%;
  }
}

.modal-toolbar {
  position: relative;
  height: 44px;
  min-height: 44px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2px;
  gap: 2px;

  & > *:only-child {
    margin-left: auto;
    @media (max-width: 991.98px) {
      margin-left: 0;
    }
  }

  @media (max-width: 991.98px) {
    &--reverse-lg {
      flex-direction: row-reverse;
    }
  }

  &-meta {
    font-weight: 600;
    line-height: 1.25;
    color: #d1d1d6;
  }

  &-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;

    @media (min-width: 992px) {
      margin-left: auto;
      margin-right: 2px;
    }
  }

  &-empty {
    width: 44px;
    height: 44px;
  }
}

.modal-header {
  padding: 0 30px 10px;

  &:first-child {
    padding-top: 30px;
  }

  @media (max-width: 991.98px) {
    padding: 0 15px 10px;

    &:first-child {
      padding-top: 20px;
    }
  }

  @media (min-width: 992px) {
    .modal-toolbar + & {
      margin-top: -14px;
    }
  }

  & &-title {
    margin-bottom: 0;

    &.mobile-center {
      @media (max-width: 991.98px) {
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}

.modal-body {
  position: relative;
  flex: 1;
  padding: 20px 30px;
  overscroll-behavior-y: contain;

  &--loading {
    display: flex;
    align-items: center;
    justify-content: center;

    &.styled_scroll {
      overflow-y: hidden !important;
    }
  }

  &:first-child {
    padding-top: 30px;
  }

  &:last-child {
    padding-bottom: 30px;
  }

  .modal-toolbar + & {
    padding-top: 0;
    margin-top: -14px;
  }

  @media (max-width: 991.98px) {
    padding: 20px 15px;

    &:first-child {
      padding-top: 15px;
    }

    &:last-child {
      padding-bottom: 15px;
    }

    .modal-toolbar + & {
      padding-top: 10px;
      margin-top: 0;
    }
  }
}

.modal-footer {
  padding: 10px 30px 30px;

  @media (max-width: 991.98px) {
    padding: 10px 15px 15px;
    //box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.15);
  }
}

.NotesBar {
  margin-top: 10px;
  padding: 15px;
  border-radius: 16px;
  background-color: #f6f6f6;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .InfoBlock {
    p {
      margin: 0;

      &.PatientInfo {
        font-weight: 700;
      }
    }
  }

  .InitNotesBtn {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.layout {
  height: 100%;

  :global(#toolbar) + :global(.container) {
    height: calc(100% - 48px);

    @media (max-width: 991.98px) {
      height: calc(100% - 44px);
    }
  }

  :global(.container) {
    height: 100%;

    :global(.box) {
      height: calc(100% + 10px);
    }

    :global(.box-body) {
      position: relative;
      flex: 1;
    }
  }
}

.chat {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: hidden;

  padding-top: 0;
  padding-bottom: 0;

  &Inner {
    height: 100%;
    width: 100%;

    float: revert;
    transform: rotate(180deg);

    @media (min-width: 992px) {
      padding-right: 10px;
    }

    @media (max-width: 991.98px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &Variants {
    transform: rotate(180deg);
    padding-bottom: 37px;
  }

  &Messages {
    width: 100%;
    display: flex;
    flex-direction: column;
    transform: rotate(180deg);

    padding-top: 20px;
    padding-bottom: 37px;

    position: relative;
  }

  &Message {
    display: flex;
    position: relative;
    max-width: 290px;

    &Inner {
      padding: 10px 15px;
      font-size: 15px;
      line-height: 1.33;
      background-color: #f6f6f6;
      border-radius: 16px;
      white-space: pre-line;
      overflow: hidden;

      &Member {
        display: flex;
        color: #17a2c6;
        font-size: 13px;
        font-weight: bold;
        line-height: 1.54;
      }
    }

    :global(.red_marker) {
      align-self: flex-end;
    }

    &Danger {
      .chatMessageInner {
        color: #ffffff;
        background-color: #ff3b30;
      }
    }

    &Left {
      align-self: flex-start;

      .chatMessageInner {
        border-bottom-left-radius: 3px;
      }
    }

    &Right {
      align-self: flex-end;

      .chatMessageInner {
        text-align: left;

        color: #ffffff;
        background-color: #17a2c6;
        border-bottom-right-radius: 3px;
      }
    }
  }

  .chatMessageLeft + .chatMessageLeft,
  .chatMessageRight + .chatMessageRight {
    padding-top: 5px;
  }

  .chatMessageLeft + .chatMessageRight,
  .chatMessageRight + .chatMessageLeft {
    padding-top: 5px;
  }

  .status {
    position: absolute;
    left: 15px;
    bottom: 10px;
    font-size: 14px;
    margin-top: 5px;
    color: #8e8e93;

    :global(.bubble-loader) {
      right: -12px;
      top: -8px;
    }
  }

  &Empty {
    position: absolute;
    bottom: 30px;
    text-align: center;

    transition: opacity 0.15s ease-in-out;

    &Content {
      position: relative;
      bottom: initial;
      padding-bottom: 10px;
    }
  }
}

.checkbox {
  & + & {
    margin-top: 5px;
  }

  & > div:before {
    background-color: #f6f6f6;
  }
}

.image,
.video,
.audio,
.document,
.unknown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  @media (max-width: 991.98px) {
    align-items: start;
  }

  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
  }
}

.image {
  img {
    max-width: 100%;
  }

  .defaultContent .icon:after {
    background-image: url('~ziphy-web-shared/basic/assets/images/ui/image.svg');
  }
}

.video {
  &:not(.isLoading):not(.isError) {
    :global(.embed-responsive),
    video {
      display: block;
    }
  }

  .defaultContent .icon:after {
    background-image: url('~ziphy-web-shared/basic/assets/images/ui/play.svg');
  }
}

.audio {
  &:not(.isLoading):not(.isDefault) {
    padding: 20px 30px 0 30px;

    @media (max-width: 991.98px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    audio {
      display: block;
      width: 100%;
    }
  }
}

.document {
  iframe,
  object {
    display: block;
    margin-bottom: 0;
    padding-bottom: 0;
    width: 100%;
    height: 100%;
    min-height: 70vh;
    max-height: 800px;
    border: 0;
  }

  object {
    p {
      padding: 20px 30px;
    }
  }

  //&:not(.isLoading):not(.isDefault) {
  //	object {
  //		display: block;
  //	}
  //}

  .defaultContent .icon:after {
    background-image: url('~ziphy-web-shared/basic/assets/images/ui/document.svg');
  }
}

.unknown {
  .defaultContent .icon:after {
    background-image: url('~ziphy-web-shared/basic/assets/images/ui/unknown.svg');
  }
}

.warning {
  max-width: 380px;
  text-align: center;
  padding: 20px 10px 40px;
}

.isLoading,
.isDefault {
  // core/library/media/MediaViewer.module.scss:4
  // modal-body min-height(400px) - padding-bottom(20px);
  min-height: 380px;

  img,
  :global(.embed-responsive),
  video,
  audio,
  canvas {
    display: none;
  }
}

.defaultContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 400px;
  min-height: 224px;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  margin: 20px 10px 15px;

  .icon {
    opacity: 0.2;
  }
}

.modalBody:global(.modal-body) {
  padding-top: 0;
}

.badgesContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.description {
  margin-top: 10px;
}

.bookButton {
  margin-top: 30px;
}

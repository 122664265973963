.day-picker-month {
  height: 428px;

  & > .body {
    padding-top: 16px;

    .row-month {
      display: flex;
      justify-content: space-around;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }

      .cell {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 114px;
        height: 48px;
        margin-right: 2px;
        font-size: 16px;
        border-radius: 16px;
        background-color: #ffffff;
        cursor: pointer;
        user-select: none;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

        @media (max-width: 767.98px) {
          font-size: 18px;
        }

        &:nth-child(3) {
          margin-right: 0;
        }

        &:hover {
          background-color: #f6f6f6;
        }

        &.cell-selected {
          font-weight: 600;
          color: #ffffff;
          background-color: #17a2c6;
        }

        &.cell-current {
          font-weight: 600;
          color: #17a2c6;

          &.cell-selected {
            color: #ffffff;
          }
        }

        &.cell-locked {
          color: #cccccc;
          cursor: default;

          &.cell-selected {
            color: #ffffff;
            background-color: #cccccc;

            &.cell-current {
              color: #ffffff;
            }
          }

          &.cell-current {
            color: #17a2c6;
            opacity: 0.5;
          }

          &:not(.cell-selected):hover {
            background-color: #ffffff;
          }
        }
      }
    }
  }
}

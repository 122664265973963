.checkbox {
  padding-right: 40px;
  margin-bottom: 0;
}

.listRow {
  position: relative;
  min-width: 100%;

  & + & {
    margin-top: 20px;
  }
}

.editButton {
  position: absolute;
  right: 0;
  top: 10px;

  &:before {
    position: absolute;
    display: block;
    content: '';
    left: -11px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 28px;
    background-color: #e0e0dd;
  }

  .icon {
    cursor: pointer;
    opacity: 0.2;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    .icon {
      opacity: 1;
    }
  }
}


.contactList {
  &Item {
    padding: 15px;
    border-radius: 16px;
    background-color: #f6f6f6;

    & + & {
      margin-top: 10px;
    }

    .title {
      font-weight: 600;
    }

    .label {
      line-height: 1.25;
      color: #8e8e93;
    }

    .buttons {
      margin-top: 10px;
      display: flex;
      gap: 10px;

      &:empty {
        margin-top: 0;
      }
    }
  }
}

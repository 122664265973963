.container {
  position: relative;
  background-color: #ffffff;
  height: 100%;
}

.box {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px 10px;

    .title {
      text-align: center;
    }
  }

  .body {
    padding: 20px 20px;
  }
}

.readIcon,
.hideIcon,
.closeIcon {
  opacity: 0.2;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

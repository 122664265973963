.paymentInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .priceForAll {
    display: inline-block;
    margin-left: 5px;
    color: #8d8e93;
    text-decoration: line-through;
    font-size: 16px;
    font-weight: normal;
  }
}

.copyVoucher {
  margin: 10px 0;
}

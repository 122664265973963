.wrapper {
  display: flex;
  align-items: center;

  .formCheck {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    .checkContainer {
      min-width: 28px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #f2f3f3;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        opacity: 0;

        g {
          fill: #ffffff;
        }
      }
    }

    .label {
      position: relative;
      font-weight: 400;
      margin-bottom: 0;
      padding: 2px 0;
      word-break: break-word;
    }

    &.gray {
      background-color: #f6f6f6;
      border: 1px solid transparent;
      border-radius: 16px;
      cursor: pointer;
      height: 48px;
      padding: 10px 15px;
      transition: all 0.1s linear;

      .checkContainer {
        background-color: #d8d8da;
      }

      .label {
        font-weight: 400;
      }

      &.invalid {
        border-color: #ff3b30;
      }
    }

    &.checked {
      .checkContainer {
        background-color: #17a2c6;

        .icon {
          opacity: 1;
        }
      }
    }

    &.fw-400 {
      .label {
        font-weight: 400;
      }
    }
  }

  .dark {
    .checkContainer {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .btn {
    flex-direction: row-reverse;
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 10px 15px;

    .label {
      line-height: 1.25;
      display: inline-block;
      width: 100%;
    }

    .checkContainer {
      min-width: 20px;
      width: 20px;
      height: 20px;
      border: 1px solid #ffffff;
      background: #ffffff url('~ziphy-web-shared/basic/assets/images/ui/minus.svg') no-repeat center;
      background-size: cover;
      border-radius: 50%;
    }

    .icon {
      min-width: 20px;
      width: 20px;
      height: 20px;
    }

    &.checked {
      .checkContainer {
        background: #17a2c6 none;
      }
    }
  }

  .readonly {
    cursor: default;

    .label {
      cursor: default;
    }

    &.btn {
      .checkContainer {
        background: #8e8e93 none;

        .icon {
          opacity: 1;
        }
      }
    }
  }

  &.withAdditionalVisible {
    .gray {
      border-radius: 16px 0 0 16px;

      &.invalid {
        border-right-color: transparent;
      }
    }

    .secondarySelectWrapper {
      width: 50%;
      min-width: 150px;
      height: 48px;

      .secondarySelect {
        .secondarySelectBorders {
          padding: 9px 15px;
          border-radius: 0 16px 16px 0 !important;
          height: 48px;

          :global(.multiselect-preview) {
            align-items: center;
          }
        }
      }
    }

    .secondaryInput {
      width: 50%;
      min-width: 200px;
      height: 48px;

      > div:first-child {
        border-radius: 0 16px 16px 0;
        display: flex;
        align-items: center;
      }
    }
  }
}

.signature {
  position: relative;
  width: 100%;
  border-radius: 10px;

  .hasError {
    border-color: #ff3b30 !important;
  }

  canvas {
    width: 100%;
    height: 100%;
  }

  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .topBar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    padding: 10px 15px;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.54;
    pointer-events: none;

    .label {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 1.5;
      color: #8e8e93;
      user-select: none;
    }

    .clearButton {
      cursor: pointer;
      color: #17a2c6;
      text-align: right;
      margin-left: auto;
      pointer-events: initial;
    }
  }
}

.signature.primary {
  border: solid 1px #e6e6e7;
}

.signature.secondary {
  border: solid 1px #e6e6e7;
  background-color: #F6F6F6;
}

.chatModal {
  :global(.modal-content) {
    height: 100%;

    @media (min-height: 700px) {
      height: 680px;
    }

    @media (min-height: 1000px) {
      height: 742px;
    }
  }

  .chat {
    position: relative;
    overflow-y: scroll !important;

    padding-top: 0;
    padding-bottom: 0;

    &Inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 100%;

      &Fixed {
        max-height: 100%;
      }
    }

    &Messages {
      width: 100%;
      display: flex;
      flex-direction: column;

      padding-top: 20px;
      padding-bottom: 20px;

      position: relative;
    }

    &Message {
      display: flex;
      position: relative;
      max-width: 290px;

      &Inner {
        padding: 10px 15px;
        font-size: 15px;
        line-height: 1.33;
        background-color: #f2f3f3;
        border-radius: 16px;
        white-space: pre-line;

        &Member {
          display: flex;
          color: #17a2c6;
          font-size: 13px;
          font-weight: bold;
          line-height: 1.54;
        }
      }

      :global(.red_marker) {
        align-self: flex-end;
      }

      &Danger {
        .chatMessageInner {
          color: #ffffff;
          background-color: #ff3b30;
        }
      }

      &Left {
        align-self: flex-start;

        .chatMessageInner {
          border-bottom-left-radius: 3px;
        }
      }

      &Right {
        align-self: flex-end;

        .chatMessageInner {
          text-align: left;

          color: #ffffff;
          background-color: #17a2c6;
          border-bottom-right-radius: 3px;
        }
      }
    }

    .chatMessageLeft + .chatMessageLeft,
    .chatMessageRight + .chatMessageRight {
      padding-top: 5px;
    }

    .chatMessageLeft + .chatMessageRight,
    .chatMessageRight + .chatMessageLeft {
      padding-top: 5px;
    }

    &Empty {
      position: absolute;
      bottom: 30px;
      text-align: center;

      transition: opacity 0.15s ease-in-out;
      user-select: none;

      &Content {
        position: relative;
        bottom: initial;
        padding-bottom: 10px;
      }
    }
  }


  :global(.modal-footer) {
    box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;

    z-index: 1;
  }

  &WithInput {
    :global(.modal-footer) {
      padding-top: 17px;
      padding-bottom: 17px;
    }

    .input {
      position: relative;
      display: flex;
      align-items: flex-end;

      &Field {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        border: none;
        outline: none;

        line-height: 1.5;
        font-weight: 600;
        color: #000000;
        background: transparent;

        resize: none;

        &::placeholder {
          color: #d1d1d6;
        }
      }

      .btn {
        position: relative;
        width: 44px;
        height: 44px;
        padding: 8px;
        margin-top: -10px;
        margin-right: -8px;
        margin-bottom: -10px;
        border: none;
        outline: none;
        cursor: pointer;
        background: url('~ziphy-web-shared/basic/assets/images/ui/send.svg') no-repeat center;

        &:disabled {
          opacity: 0.3;
          filter: grayscale(100%);
        }

        &:focus,
        &:active {
          outline: none;
        }
      }
    }
  }
}

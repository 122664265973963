.wrapper {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: auto;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.2px;
  text-align: center;
  color: #8d8e93;

  a {
    color: #8d8e93;

    &:hover,
    &:focus {
      color: #8d8e93;
    }
  }
}
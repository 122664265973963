.appt_survey {
  &-item {
    & + & {
      margin-top: 30px;
    }
  }

  &-label {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.78;
    margin-bottom: 10px;
    padding-right: 12px;

    &--required {
      span {
        position: relative;

        &:after {
          content: '*';
          position: absolute;
          left: calc(100% + 4px);
          display: inline-block;
          color: #ff3b30;
        }
      }
    }
  }
}

.survey-btns-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.h_table {
  $P: &;

  display: table;
  width: 100%;
  box-sizing: border-box;
  border-collapse: collapse;
  empty-cells: show;

  font-size: 14px;
  line-height: 1.43;

  @media (max-width: 991.98px) {
    display: flex;
    flex-direction: column;
  }

  &--empty {
    display: block;
  }

  &-thead {
    display: table-header-group;

    @media (max-width: 991.98px) {
      display: none;
    }

    #{$P}-tr {
      color: #8e8e93;
      cursor: default;
    }
  }

  &-tbody {
    display: table-row-group;

    @media (max-width: 991.98px) {
      display: flex;
      flex-direction: column;
    }

    &--loading {
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.7);
      }
    }

    .h_table--empty & {
      display: block;
      text-align: center;
      padding: 15px;
    }
  }

  &-tr {
    display: table-row;
    border-top: 1px solid #e1e1e1;

    cursor: pointer;

    &--disabled {
      cursor: default;
    }

    @media (max-width: 991.98px) {
      display: flex;
      flex-wrap: wrap;

      padding-top: 10px;
      padding-bottom: 10px;
    }

    #{$P}-thead &:first-child {
      border-top: 0;
      border-bottom: 1px solid #e1e1e1;
    }

    #{$P}-tbody &:first-child {
      border-top-width: 0;
    }

    &--loader {
      position: relative;
      cursor: default;
      height: 106px;

      @media (max-width: 991.98px) {
        height: 76px;
      }

      > div > div {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -28px;
        margin-top: -28px;
      }
    }
  }

  &-th,
  &-td {
    display: table-cell;
    padding: 10px 15px;

    min-width: 144px;

    @media (max-width: 991.98px) {
      min-width: 124px;
    }
    @media (max-width: 991.98px) {
      display: block;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &-td {
    @media (max-width: 991.98px) {
      flex: 1;
      min-width: auto;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }
}

.h_table--last_border {
  .h_table-tr:last-child {
    border-bottom: 1px solid #e1e1e1;
  }
}

.h_table--medical_history,
.h_table--appointments,
.h_table--labs,
.h_table--medications,
.h_table--documents {
  $P: '.h_table';

  #{$P}-th {
    &--details {
      width: 100%;
    }
  }

  #{$P}-tr {
    @media (max-width: 991.98px) {
      .h_table-td--label,
      .h_table-td--date {
        padding-top: 0;
      }
    }
  }

  #{$P}-td {
    &--delimiter {
      display: none;
      padding: 0;
      margin: 0;
      height: 0;

      @media (max-width: 991.98px) {
        display: block;
        order: 2;
        flex-basis: 100%;
      }
    }

    &--label {
      padding-left: 0;

      @media (max-width: 991.98px) {
        order: 0;
      }
    }

    &--details {
      width: 100%;

      &:first-letter {
        text-transform: uppercase;
      }

      @media (max-width: 991.98px) {
        order: 3;
      }

      .text-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .badge-self_reported {
        position: relative;
        margin-left: 5px;
        top: -1px;
      }
    }

    &--code {
      white-space: nowrap;

      @media (max-width: 991.98px) {
        order: 4;
        color: #8e8e93;
        text-align: right;
        flex: 0;
        padding-left: 15px;
      }
    }

    &--date {
      white-space: nowrap;

      @media (max-width: 991.98px) {
        text-align: right;
        order: 1;
      }
    }
  }
}

.h_table--medical_history {
  $P: '.h_table';
}

.h_table--appointments {
  $P: '.h_table';
}

.h_table--labs {
  $P: '.h_table';

  #{$P}-td {
    &--status {
      white-space: nowrap;

      @media (max-width: 991.98px) {
        order: 4;
        text-align: right;
        flex: 0;
        padding-left: 15px;
        font-size: 16px;
      }
    }

    &--date {
      .event_status {
        display: none;
        color: #000000;
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }
}

.h_table--medications {
  $P: '.h_table';
}

.h_table--documents {
  $P: '.h_table';

  #{$P}-td {
    &--label {
      min-width: 288px;

      @media (max-width: 991.98px) {
        min-width: 1px;
      }
    }

    &--details {
      &-label {
        display: none;
      }
    }
  }
}

.h_table--appointments {
  $P: '.h_table';

  #{$P}-td {
    &--label {
      min-width: 170px;

      @media (max-width: 991.98px) {
        min-width: 1px;
      }
    }
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-bottom: -10px;
}

.symptomContainer {
  position: relative;
  display: flex;
  width: calc(20% - 10px);
  margin-left: 10px;
  margin-bottom: 10px;

  @media (max-width: 767.98px) {
    width: calc(33.333% - 10px);
  }
}

.label {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 10px;
  margin-bottom: 0;
  padding: 10px 10px;
  cursor: pointer;

  background-color: #f6f6f6;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #eeefef;
  }

  img {
    max-width: 100%;
  }

  input {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
  }
}

.text {
  display: block;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.54;
  text-align: center;
  color: #000000;
  margin-top: auto;
}

.checkbox {
  position: absolute;
  right: 10px;
  top: 10px;
}

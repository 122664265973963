.categoryName {
  margin-top: 20px;
  margin-bottom: 10px;

  font-weight: 600;
  line-height: 20px;
}

.listContainer {
  background-color: #f6f6f6;
  border-radius: 10px;
}

.notFound {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .button {
    width: fit-content;
  }
}

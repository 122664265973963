.noResults {
  border-radius: 16px;
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  text-align: center;
  font-weight: 600;
  line-height: 1.5;

  a {
    line-height: inherit;
  }
}

.md {
  padding: 19px 30px;
}

.lg {
  padding: 29px 30px;
}

.bordered {
  border-color: #e6e6e7;
}

.dropdown {
  //min-width: 220px;
  max-width: 420px;
}

.dropdownButton {
  display: block;
  padding: 8px 15px;
  border-radius: 20px;
  font-weight: 700;
  cursor: pointer;

  transition: color 0.3s ease-in-out;

  color: #000000;
  background-color: #f6f6f6;

  > div {
    border-bottom: 1px #000000 dashed;
    transition: border-bottom-color 0.3s ease-in-out;

    min-width: 1%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.topLeft {
  border-top-left-radius: var(--connected-group-border-radius) !important;
}

.topRight {
  border-top-right-radius: var(--connected-group-border-radius) !important;
}

.bottomLeft {
  border-bottom-left-radius: var(--connected-group-border-radius) !important;
}

.bottomRight {
  border-bottom-right-radius: var(--connected-group-border-radius) !important;
}

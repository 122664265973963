.image,
.video,
.audio,
.document,
.unknown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  cursor: pointer;

  &:before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.08);
    z-index: 1;
  }

  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
  }
}

.image {
  &:after {
    background-image: url('~ziphy-web-shared/basic/assets/images/ui/image.svg');
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.video {
  &:after {
    background-image: url('~ziphy-web-shared/basic/assets/images/ui/play.svg');
    background-position: 1px center;
  }

  &:not(.isError) {
    &:after {
      border-radius: 100%;
      opacity: 1;
      background-color: rgb(123 123 123);
      background-image: url('~ziphy-web-shared/basic/assets/images/ui/play-white.svg');
    }

    video {
      display: block;
    }
  }
}

.audio {
  audio {
    display: none;
  }
}

.document {
  &:after {
    background-image: url('~ziphy-web-shared/basic/assets/images/ui/document.svg');
  }

  &.withDate {
    &:after {
      transform: translate(-50%, -75%);
    }

    .documentDate {
      margin-top: 50px;
      font-size: 14px;
      color: #666;
    }
  }
}

.unknown {
  &:after {
    background-image: url('~ziphy-web-shared/basic/assets/images/ui/unknown.svg');
  }
}

.isLoading,
.isDefault {
  img,
  video,
  canvas,
  object {
    display: none;
  }
}

.isDefault {
  &:after {
    display: block;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-size: 100%;
    width: 42px;
    height: 42px;
    opacity: 0.2;
    z-index: 1;
  }

  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
    z-index: 1;
  }
}

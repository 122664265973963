.wrapper {
  border-radius: 16px;
  background-color: #f6f6f6;
  transition: background-color 0.3s ease-in-out;

  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  & + & {
    margin-top: 10px;
  }
}

.body {
  display: flex;
  align-items: center;
  padding: 10px 15px;

  .content {
    position: relative;
    flex: 1;

    .title {
      display: flex;
      align-items: center;
      font-weight: 600;
    }

    .titleText {
      flex: 1;
    }

    .titlePrice {
      color: #8e8e93;
      margin-left: 5px;
    }
  }

  .checkbox + .content {
    margin-left: 15px;
  }
}

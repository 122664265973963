.item {
  position: relative;

  padding: 15px 20px;
  border-bottom: 1px solid rgba(206, 206, 208, 0.5);

  cursor: pointer;
  text-transform: capitalize;

  &:last-child {
    border-bottom: none;
  }
}

.checkbox {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

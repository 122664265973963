.WindowSlotPicker {
  position: relative;

  padding-top: 25px;
  padding-bottom: 10px;
}

.body {
  .rowWrapper {
    padding-bottom: 25px;

    &:last-child {
      padding-bottom: 0;
    }

    .chunkDay {
      padding-bottom: 8px;
      font-size: 18px;
      font-weight: 600;
    }

    .rowSlot {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
      max-width: 698px;

      .cell {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        width: calc((100% - 50px) / 6);
        height: 40px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 10px;
        background-color: #f6f6f6;
        cursor: pointer;
        user-select: none;
        transition: background-color 0.2s ease-in-out,
        color 0.2s ease-in-out;
        @media (max-width: 698px) {
          width: calc((100% - 30px) / 4);
        }
        @media (max-width: 500px) {
          width: calc((100% - 20px) / 3);
        }

        .icon {
          display: none;
          transition: width 0.15s ease-in-out;
        }

        &.cellSelected {
          font-weight: 600;
          color: #ffffff;
          background-color: #17a2c6;

          .icon {
            display: block;

            g {
              fill: #ffffff;
            }
          }
        }
      }
    }
  }
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 56px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.6);
}
